module.exports = global.config = {
  Home: [
    { text: "" },
    {
      text: "The school is affiliated with Central Board of Secondary Education, New Delhi. It is located 2 miles away from Balachaur on Bhaddi Road. The school campus sprawls over 6.7 acres of well-developed and picturesque landscape. The spacious and modern building of the school heralds welcome to entrants owing to its majestic structure. It is surrounded on all sides by its eye catching lush green gardens overflowing with flowers of different hues which promotes their fragrance all around.",    
    },
    {
      text: "M.R. Citi Public School, Balachaur is a hope, a dream transformed into reality. It is a secular, co-educational day boarding school dedicated to the pursuit of excellence. Whether in the field of academics, sports, arts or music, we believe that every child has inherent talents that we strive to unearth and foster through individual attention.",
    },
    {
      text: "It aims to provide a holistic education and has comprehensive facilities to develop the child's all-round personality.",
    },
    // { texth: "Consult now" },
    // {
    //   textc:
    //     "At Ardent, we value our client's right to justice above all. And that's precisely what has helped us cement our position in the justice system over the years. Whether its Criminal Law, Real Estate Law, Family Law, Business Law or Immigration Law, we have a dedicated team of specialists to assist you.",
    // },
    // {
    //   textc:
    //     "In short, we are a full-service Law firm in Ontario that specializes in assisting clients from various sections of society. ",
    // },
  ],
  About: [
    { text: "" },
    {
      text: "Ardent Law firm resulted from an idea to establish a reliable, trustworthy & full-service Law Firm.",
    },
    {
      text: "At Ardent Law Firm, we have accumulated years of experience & a team of super-talented and specialized lawyers. We listen to our clients' issues in detail & later offer our tailored solutions to help them out.",
    },
    {
      text: "We believe in listening & inquiring about every little detail that matters. So when you hire us, you can expect good attention to everything you have to say. On top of it, we will ask the pointed but vital questions for additional information The better you answer them, the better we will be able to offer the solution. Because, ultimately, it's your answers that will help us prepare a rock-solid case for you.",
    },
    { texth: "Consult now" },
    {
      textc:
        "At Ardent, we value our client's right to justice above all. And that's precisely what has helped us cement our position in the justice system over the years. Whether its Criminal Law, Real Estate Law, Family Law, Business Law or Immigration Law, we have a dedicated team of specialists to assist you.",
    },
    {
      textc:
        "In short, we are a full-service Law firm in Ontario that specializes in assisting clients from various sections of society. ",
    },
  ],
  FamilyLaw: [
    { text: "" },
    {
      text: "Legal separations & divorce can be tumultuous & most painful times in your life. It can be too much to take, especially when you have children. Apart from this, such legal family issues can impact many other aspects of your life, including Parental rights, Assets & Personal properties. These are very crucial aspects of your life & hence you need the proper guidance from the best family lawyer in Ontario. You cannot rely on anyone other than a professional & experienced team of family law specialists like Ardent Law firm. ",
    },
    {
      text: "Ardent Law firm, can help you in multiple ways. From court cases & arbitration to mediation, we can help you get the best outcome from your legal family issues",
    },
    {
      text: "We understand separations can be heart-breaking & tumultuous. So at Ardent Law firm, we offer reliable guidance & suitable options to you. Apart from the proper guidance, we offer an emotionally helping environment to help you make the right decision.",
    },
    {
      text: "Our team of lawyers takes a unique approach to help you settle your legal family disputes in or out of court. Our team is always ready to handle your case in court, no matter the complexities. We will handle every part of your case & will represent you in the best possible manner. Instead of going with the same strategy for all clients, we craft our approach according to each client’s unique case. This helps us get a better outcome for our valued clients in family matters. ",
    },
    {
      text: "From the negotiations for Property divisions, personal assets & parental rights, we constantly stay in touch with you to offer the right advice. Apart from our professional legal services to handle your case in court, we also offer unbundled legal services designed specifically for you",
    },
  ],
  CivilLitigation: [
    { text: "" },
    {
      text: "At Ardent Law firm, we offer professional & tailored civil litigation services to our clients according to their needs and goals. Our goal is to dedicatedly work with our clients & develop strategies that will help them get their desired outcomes. We don't back down from the fight for our clients at multiple levels, including tribunals & Provincial/Federal commissions alike. We care about our valued Clients & everything legally possible to help them get the best results in Civil litigation lawsuits. At Ardent Law Firm, we understand how much you rely on your civil litigation lawyer. So we take it as our moral responsibility to offer you the best services & guidance at every stage of your litigation process. ",
    },
    { texth: "What services do we offer?" },
    { textc: "• Breach of Contract" },
    { textc: "•	Tort Litigation" },
    { textc: "•	Professional Negligence" },
    { textc: "•	Appeals and Judicial Reviews" },
    { textc: "•	Partnership and Business Disputes" },
    { textc: "•	Injunctions and Ex Parte Motions" },
    { textc: "•	Estate Litigation" },
    { textc: "•	Disability Benefits" },
    { textc: "•	Real Estate Litigation and Latent Defect Claims" },
    { textc: "•	Breach of Charter of Rights and Freedoms" },
    { textc: "•	Defamation" },
    { textc: "•	Insurance disputes" },
    { textc: "•	Collection actions" },
  ],
  BusinessLaw: [
    { text: "" },
    {
      text: "Businesses need a certified & highly efficient Business Lawyer Ontario to manage their Business operations efficiently. Whether it's an addition or removal of a critical stakeholder, buy or sale of any property or Business, acquisition or financing, no Business can operate smoothly without a team of professional Business lawyers in Canada. ",
    },
    {
      text: "So if you are a corporate or a rapidly growing business, it's better to have a team of lawyers who can manage all your legal Business issues effectively. At Ardent Law firm, we offer tailored & fully customized legal services to Businesses of all sizes. ",
    },
    {
      text: "Our goal is always to avoid confrontations & mitigate the risk with dispute resolutions & mediation. In short, we will do everything, keeping your best interest in mind. ",
    },
    { texth: "What services do we offer?" },
    {
      textc:
        "Our comprehensive Corporate Lawyer services include everything that your Business will ever need. Here is the list of services that we offer.",
    },
    { textc: "•	Shareholder and partnership agreements" },
    { textc: "•	Employment agreements" },
    { textc: "•	Incorporation (federal and provincial)" },
    { textc: "•	Contract negotiation" },
    { textc: "•	Buying and selling businesses" },
    { textc: "•	Commercial agreements" },
    { textc: "•	Supply and distribution agreements" },
    { textc: "•	Mediation for Disputes (corporate, management, shareholder)" },
    { textc: "•	Mergers and acquisitions" },
    { textc: "•	Non-compete and Non-disclosure agreements" },
  ],
  CriminalLaw: [
    { text: "" },
    {
      text: "We are a team of professional & specialized criminal lawyers with years of experience. When it comes to legal advice, we offer precise, reliable & the best guidance to our clients & help secure their future. We focus on solving cases as quickly & as efficiently we can.",
    },
    {
      text: 'We strongly believe in the principle of Canadian law, "Innocent until Proven Guilty". And to uphold this principle, we go a step beyond helping our clients out.',
    },
    {
      text: "Regardless of the case, we approach it with zero compromise & unflinching dedication to bringing out the best possible outcome for our clients. ",
    },
    {
      text: "We fully understand how shocking & scary it is when being charged criminally. However, a small mistake on your lawyer's end & your entire life can be ruined. So we deploy all our resources to make sure you get the most favourable outcome from the entire case.",
    },
    {
      text: "No matter how complex a criminal case is, we have a team of professionals with specialization in every criminal law. Whether its trial or appeal, we have the best team to handle all types of cases. ",
    },
    {
      text: "When it comes to defending our clients, we act fearlessly & vehemently to protect our clients' interests. We believe in zero compromises & deliver the best outcomes from every case. We pay absolute attention to each case to ensure our clients' self–interests are preserved.",
    },
    {
      text: "Our team specializes in almost every type of Criminal charge brought up against our client. ",
    },
    { text: "" },
    { text: "Here is the list:" },
    { text: "" },
    { text: "•	Assault" },
    { text: "•	Domestic Assault" },
    { text: "•	Aggravated Assault and Assault with a Weapon" },
    { text: "•	Uttering Threats" },
    { text: "•	Criminal Harassment" },
    { text: "•	Sexual Assault" },
    { text: "•	Fraud and Credit Card Possession" },
    { text: "•	Mischief Under $5000 and Mischief Over $5000" },
    { text: "•	Shoplifting / Theft Under $5000" },
    { text: "•	Dangerous Driving" },
    { text: "•	Fail to Comply with Bail" },
    { text: "•	Drug Possession and Drug Importing" },
    { text: "•	Drug Trafficking" },
    { text: "•	Homicides: Murder and Manslaughter" },
    { text: "•	Weapons Possession" },
    { text: "•	Bail Hearings and Bail Detention Reviews" },
    { text: "•	Extradition Hearings" },
    { text: "•	Firearms Possession and Other Gun Charges" },
    { text: "•	Young Offenders" },
    { text: "•	Victim Support and Assistance" },
  ],
  ImmigrationLaw: [
    { text: "" },
    {
      text: "Planning to migrate or settle in Canada, one of the best countries in the world? Excellent decision! Canada offers immense opportunities, be it for newcomers from all over the globe or for temporary residents planning to settle here. Immigration Law covers anything and everything from Citizenship, Permanent residence, Spousal sponsorship, Study permit, Work permit, temporary residence, Refugee and Humanitarian applications and Judicial Review.",
    },
    {
      text: "The immigration laws & intake capacities in Canada are constantly updated by the Canadian Government. We, at Ardent Law Firm, specialize in all types of immigration issues and focus on turning the dreams of aspiring immigrants into reality. Our dedicated team is well versed and up to date with the constantly changing immigration laws and requirements. ",
    },
    {
      text: "We, at Ardent Law Firm, will sit down with you to understand your exact immigration needs and will offer you the best in depth knowledge about all the routes available to you to immigrate and settle in Canada. The applications can be overwhelming; however, our team will assist you throughout the process all the way from filling out the forms, online profiles to ironing out any legal issues or complications.",
    },
    { texth: "We specialize in the following areas:" },
    {
      textc:
        "Temporary Residence including Visit Visa, Work Permits, Study Permits.",
    },
    { textc: "Permanent Residence including Express Entry, PNP’s" },
    {
      textc:
        "Sponsorship Applications including Spousal sponsorship, Parental sponsorship, Child sponsorship",
    },
    { textc: "Investors category and Self-employed category" },
    { textc: "Refugee Applications" },
    { textc: "Judicial Review and Reconsideration" },
  ],
  RealEstateLaw: [
    { text: "" },
    {
      text: "Making a decision to buy any property is a big one, financially & emotionally. So you need support from a team of experienced & reliable Real Estate Law experts in Ontario. And we are here to provide you with that support. ",
    },
    {
      text: "At every stage of your Property buying or selling, we will ensure you are not stuck or face some legal issues. And in case anything goes wrong, we will also represent you in Real Estate Litigation to protect your rights. We are a Professional Real Estate Lawyer Ontario team specializing in Real Estate laws. Our team is well-equipped with the knowledge & resources needed to ensure a smooth buying of real estate. When it comes to the legality of all the paperwork, our team acts as your support line. We will perform an extensive analysis of your contract to ensure everything is in line.",
    },
    {
      text: "We will make sure you are getting what you expect & nothing less. Apart from this, we will also help you negotiate transaction terms on your behalf so you can sit back & relax. Our lawyers are well-versed in terms of laws & hence will ensure you are not misled in any form via your contract. We will do everything in our power to ensure that your rights are protected in the best possible way. ",
    },
    {
      text: "On Closing Day, we will assist you at every step of the final process. As this is the day when you will actually become your property owner, we will ensure all the paperwork is in line. Our team will make sure nothing goes wrong at the last moment & hence protect you from any fraud or legal issues. ",
    },
    {
      text: "We will also help you with making financial transactions & in creating Statements of Adjustments. We will ensure you are not charged with any unnecessary fees & are getting precisely what you were promised.",
    },
  ],
  WillsEstate: [
    { text: "" },
    {
      text: "At Ardent Law firm, we provide Will & Estate services to help our esteemed client in Ontario.  Whether you want to prepare your own Will or our legal services in the property division, we are your go-to Wills & Real Estate Lawyer Ontario.  We make the whole process extremely easy & convenient for family members in such cases.  ",
    },
    {
      text: "Our team offers pragmatic advice on specific complex planning issues like the Creation of Trust, Guardianship or Succession of Business.  In short, no matter what your specific needs are or what particular services you want, our team is here to help you through any complexities related to Will & Real Estate.",
    },
    { texth: "What services do we offer?" },
    { textc: "•	Wills & Codicils" },
    { textc: "•	Powers of Attorney for Property and Personal Care" },
    { textc: "•	Guardianship" },
    { textc: "•	Estate & Tax Planning" },
    { textc: "•	Estate Freezes" },
    { textc: "•	Estate Distribution and Administration" },
  ],
  Address: {
    schoolName:"M.R. CITI PUBLIC SCHOOL",
    caption: "Office Address",
    street: "C.B.S.E. Affiliation No. 1630222, School Code : 20218",
    city: "Bhaddi Road, Balachaur, Distt. S.B.S. Nagar (Punjab)  Pin Code : 144521",
    phone: "Phone: 01885-291002,78148-19100",
    phoneInternational: "01885-291002,78148-19100",
    email: "Email: mrcitischoolblcr99@gmail.com",
    emailId: "Public Disclousre",
  },

  PageMenu: {
    FamilyLaw: {
      icon: "fi-br-address-card",
      title: "Introduction",
      content:"The school is affiliated with Central Board of Secondary Education, New Delhi. It is located 2 miles away from Balachaur on Bhaddi Road....",
      link: "../Introduction",
    },
    CivilLitigation: {
      icon: "fi fi-br-c",
      title: "Vision \& Mission",
      content:"The school with its motto “Darkness to Light” has basic vision of providing quality education and the right inputs to help children grow...",
      link: "../VisionMission",
    },
    BusinessLaw: {
      icon: "fi fi-br-m",
      title: "Sports",
      content:"The school has a spacious playground and has facilities for athletics, basketball, volley ball, handball, fencing etc. A separate play area...",
      link: "../Sports",
    },
    CriminalLaw: {
      icon: "flaticon-thief",
      title: "School Codes & Policies",
      content:"To Maintain strict discipline to speed up progress and efficiency we are compelled to take certain precautionary measures in the school...",
      link: "../SchoolCodesPolicies",
    },
    Immigration: {
      icon: "flaticon-university-graduate-hat",
      title: "Guidance & Counselling",
      content:"To facilitate the holistic development of our students without any obstacle of learning disabilities, physical disabilities and behavioural...",
      link: "../GuidanceAndCounselling",
    },
    RealEstateLaw: {
      icon: "flaticon-house",
      title: "Personal And Social Education",
      content:"At M.R. Citi School we attach great importance to the identification and achievement of personal goals. We lay special stress on planning...",
      link: "../PersonalandSocialEducation",
    },
    WillsEstate: {
      icon: "flaticon-house",
      title: "Cleanliness",
      content:"We hold profound belief in the adage, \"Cleanliness is next to goodliness.\" Extra care is taken by us to ensure cleanliness in and around...",
      link: "../Cleanliness",
    },
    Introduction: {
      icon: "fi-br-address-card",
      title: "Introduction",
      content:"The school is affiliated with Central Board of Secondary Education, New Delhi. It is located 2 miles away from Balachaur on Bhaddi Road....",
      link: "../Introduction",
    },
    VisionMission: {
      icon: "fi-sr-list-timeline",
      title: "Vision \& Mission",
      content:"The school with its motto “Darkness to Light” has basic vision of providing quality education and the right inputs to help children grow...",
      link: "../VisionMission",
    },
    Sports: {
      icon: "fi-ss-games",
      title: "Sports",
      content:"The school has a spacious playground and has facilities for athletics, basketball, volley ball, handball, fencing etc. A separate play area...",
      link: "../Sports",
    },
    SchoolCodesPolicies: {
      icon: "fi-br-clipboard-list-check",
      title: "School Codes & Policies",
      content:"To Maintain strict discipline to speed up progress and efficiency we are compelled to take certain precautionary measures in the school...",
      link: "../SchoolCodesPolicies",
    },
    GuidanceAndCounselling: {
      icon: "flaticon-university-graduate-hat",
      title: "Guidance & Counselling",
      content:"To facilitate the holistic development of our students without any obstacle of learning disabilities, physical disabilities and behavioural...",
      link: "../GuidanceAndCounselling",
    },
    PersonalandSocialEducation: {
      icon: "fi-br-social-network",
      title: "Personal And Social Education",
      content:"At M.R. Citi School we attach great importance to the identification and achievement of personal goals. We lay special stress on planning...",
      link: "../PersonalandSocialEducation",
    },
    Cleanliness: {
      icon: "fi-rs-clean",
      title: "Cleanliness",
      content:"We hold profound belief in the adage, \"Cleanliness is next to goodliness.\" Extra care is taken by us to ensure cleanliness in and around...",
      link: "../Cleanliness",
    },
    Teachers: {
      icon: "fi-ss-user-pen",
      title: "Teachers",
      content:"The main strength of an educational institute is its teachers. The teachers are backbone of an educational system. The faculty of MR Citi Public School consist of highly qualified teachers...",
      link: "../Teachers",
    },
    SchoolUniform: {
      icon: "fi-ss-uniform-martial-arts",
      title: "School Uniform",
      content:"On all working days and at school functions, children should wear theuniform prescribed for the season:...",
      link: "../SchoolUniform",
    },
    // rest of your translation object
  },
  // other global config variables you wish
};
