import React from 'react';
import './style.css'; // Import CSS for modal styles

const Modal = ({ isOpen, imageSrc, onClose }) => {
  if (!isOpen) return null; // Don't render modal if it's not open

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        <img src={imageSrc} alt="Full screen" className="modal-image" />
      </div>
    </div>
  );
};

export default Modal;
