import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import { createAPIEndpoint, ENDPOINTS } from  "../../api";
// images
import testmonial from "../../images/testimonial/1.png";
import testmonial2 from "../../images/testimonial/2.png";
 
const slider = [
  {
    text: "Dear Parents<br/>School will remain closed on 23 May 2023 on account of Martyrdom Day of Sri Guru Arjun Dev Ji. It will reopen on 24 May 2023 and School timings will be 7:55 am to 10:30 am.Very professional people. They took time with my application, never rush me through my appointment. I would highly recommend them for your application process.</br><b>Principal</b>",
    images: testmonial2,
    title: "HOLIDAY",
    subTitle: "22 May 2023",
  },
  {
    text: "Dear Parents <br/>School will remain closed on 14 April 2023 on account of Vaisakhi and Ambedkar Jayanti. It will reopen on 15 April 2023.<br/><b>Principal</b>",
    images: testmonial2,
    title: "HOLIDAY",
    subTitle: "13 Apr 2023",
  },
 
];

function getimage(src){
if(src==="")
{
  return testmonial2;
}
else
{
return "data:image/png;base64,"+src;
}
}


const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};
const ScrollNews = ({ className }) => {
  //Start
const [data, setData] = useState([]);

 

useEffect(() => {
  fetch("/source/news.txt")
    .then((response) => response.json())
    .then((actualData) => {
       
      setData(actualData);
    })
    .catch((err) => {
      console.error("Failed to fetch data:", err.message);
    });
}, []);
//End
function myHtmlRender(ptext) {
  return <div     dangerouslySetInnerHTML={{__html: ptext}}  />;
}
return (
  <div className={className}>
    <div className="container">
      <div className="row">
        <div className="col-lg-11 offset-lg-1 col-md-6">
          <div className="testimonialContent">
         <a href="#/NoticeBoard">   <h2>Notice Board</h2></a>
            <Slider className="testmonialSlider" {...settings}>
              {data.map((slider, i) => (
              
                <div key={i} className="slideItem" style={{ textAlign: "justify" }}>
                
                  <h4>{slider.title}</h4>
                  {slider.subTitle}
 
                  <p>{myHtmlRender(slider.text)}</p>
              
                </div>
            
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};
  // return (
  //   <div className={className}>
  //     <div className="container">
  //       <div className="row">
      
  //         <div className="col-lg-11 offset-lg-1 col-md-6">
  //           <div className="testimonialContent">
  //              <h2>Notice Board</h2>
  //             <Slider className="testmonialSlider" {...settings}>
  //               {slider.map((slider, i) => (
  //                 <div key={i} className="slideItem" style={{ textAlign: "justify;"}}>
  //                   <h4>{slider.title}</h4>
  //                   {slider.subTitle}
  //                   <p>{myHtmlRender( slider.text)}</p>
                    
                        
  //                   {/* <div className="thumbWrap">
  //                     <div className="thumbImg">
  //                       <img src={
  //                             getimage(slider.images)
                          
  //                         } alt="" />
  //                     </div>
  //                     <div className="imgContent">
  //                       <h4>{slider.title}</h4>
  //                       <span>{slider.subTitle}</span>
  //                     </div>
  //                   </div> */}
  //                 </div>
  //               ))}
  //             </Slider>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
 

export default ScrollNews;
 
