import axios from 'axios'

export const BASE_URL = 'https://api.mrcitisrsecschool.co.in/';

export const ENDPOINTS = {
    getTestimonials:"getTestimonials",
    email: 'SendEmail',
    question:'question',
    getAnswers : 'question/getanswers'
}

export const createAPIEndpoint = endpoint => {
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    let url = BASE_URL + 'api/' + endpoint + '/';
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    return {
        fetch: () => axios.get(url),
        fetchById: id => axios.get(url + id),
        post: newRecord => axios.post(url, newRecord),
        put: (id, updatedRecord) => axios.put(url + id, updatedRecord),
        delete: id => axios.delete(url + id),
    }
}