import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: "<iframe src=\"images/mrcitisrsecschool/Misc/Application%20form%20for%20change%20in%20address%20or%20phone%20number.pdf\" id\"pdf\"=\"\"></iframe>" },];;

const services = [{icon:"fi fi-br-s",title:"Student Profile Updation Perfroma",content:"<iframe src=\"images/mrcitisrsecschool/Misc/Other%20Information.pdf\" =\"\"=\"\"></iframe>",link:"../StudentProfileUpdationPerfroma",},{icon:"fi fi-br-s",title:"School Leaving Certificate",content:"<iframe src=\"images/mrcitisrsecschool/Misc/Transfer.pdf\" =\"\"=\"\">&amp;amp;amp;amp;amp;amp;amp;lt;/iframe</iframe>",link:"../SchoolLeavingCertificate",},{icon:"fi fi-br-b",title:"Bonafide/Fee Certificate",content:"<iframe scrolling=\"yes\" src=\"images/mrcitisrsecschool/Misc/Bonafide%20Student.pdf\">&amp;amp;amp;amp;amp;amp;amp;lt;/iframe</iframe>",link:"../BonafideFeeCertificate",},{icon:"fi fi-br-c",title:"Change In Transport Facility",content:"<iframe src=\"images/mrcitisrsecschool/Misc/transport.pdf\" =\"\"=\"\"></iframe>",link:"../ChangeinTransportFacility",},{icon:"fi fi-br-i",title:"Issue Of Duplicate I-Card",content:"<iframe src=\"images/mrcitisrsecschool/Misc/I-card.pdf\" =\"\"=\"\"></iframe>",link:"../IssueofDuplicateICard",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Change Of Address/Phone No.'}
]

const ChangeofAddressPhoneNo = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Change Of Address/Phone No."
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="ChangeofAddressPhoneNo"
                subTitle="Change Of Address/Phone No."
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default ChangeofAddressPhoneNo
