import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: "<br />Mrs. Ritu Batra, Principal is a post graduated in commerce and has done philosophy in the same with an impeccable service record of an educational career that spans over more than 18 years to her credit. An expert in School Educational management she is much respected and credited in the vicinity for successfully managing best school in S.B.S Nagar. A product of convent school, she brings along rich cultural diversity and aptitude. <br /><br /> As an educationist she is visionary, dynamic and well proficient in modern trends in education, she is well known for successfully managing and guiding academic, co-curricular and extracurricular activities. In her role as a principal, she has expertise in curriculum design, technology in education assessment and evaluation, Professional development for teachers, pedagogies and school administration skills. <br /> <br /> Her vision and acumen coupled with a strong urge for leading edge initiative and extra ordinary approach in the field of school education is certainly an asset to MR Citi Public School.<br /> <br /> <br />" },];

const services = [{icon:"fi fi-br-h",title:"Heads",content:"&nbsp;<br /> “Leader in Me is the ideal Blueprint for every school to change the world through education”<br /><br />   <div>      <img src=\"images/mrcitisrsecschool/Misc/Jaspreet (Nur to UKG).jpg\" />  <img src=\"images/mrcitisrsecschool/Misc/Poonamthakur.jpg\" />      <img src=\"images/mrcitisrsecschool/Misc/NeeruSharma.jpg\" />      <img src=\"images/mrcitisrsecschool/Misc/KiranDatta.jpg\" />      <img src=\"images/mrcitisrsecschool/Misc/Gurpreetkaursekhon.jpg\" /> </div>   <br />  <br /> <br />",link:"../Heads",},{icon:"fi fi-br-t",title:"Teachers",content:"<br /><img src=\"images/mrcitisrsecschool/Misc/Staffpic.jpg\" /><img src=\"images/mrcitisrsecschool/Misc/AdminStaff.jpg\" /><br /><br /><div>The main strength of an educational institute is its teachers. The teachers are backbone of an educational system. The faculty of M.R. Citi Public School consist of highly qualified teachers. Who have extensive teaching experience in their respective fields.&nbsp;<div>For the teachers of M.R. Citi Public School ‘",link:"../Teachers",},{icon:"fi fi-br-t",title:"Teachers Enrichment Programme",content:"<br /><div>Both staff and administrations participate in training workshops for personal improvement and professional learning opportunities.&nbsp;</div><div>Administration, teachers and their supporting staff members need opportunities for meaningful professional development as they strive to impact students.&nbsp;</div><div>These enrichment programs conducted in MR Citi Public School focuses on helping staff build character, explore new paradigms and develop highly ",link:"../TeachersEnrichmentProgramme",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'About The Principal'}
]

const AboutthePrincipal = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="About The Principal"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="AboutthePrincipal"
                subTitle="About The Principal"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default AboutthePrincipal
