import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: "<br /><div><span>CBSE Affiliated / Accreditation </span></div><div><span><br /></span></div><div><span>M.R Citi Public School is affiliated with the Central Board of Secondary Education (CBSE) which is the largest educational board in the country and is duly recognised by Department of Education, Govt. of Punjab vide affiliation number 1630222.&nbsp;&nbsp;</span></div><div><span><br />At M.R. Citi Public School.</span></div><div><span>We believe in imparting quality education with excellence and commitment. M.R. Citi believes that education must be served to served to every child and interest for education must be developed in every individual to pursue a positive approach in life.&nbsp;</span></div><div><span><br /></span></div><div><span>M.R. Citi Public School, Balachaur is affiliated to CBSE with classes from Nursery to XII and uses NCERT books in various subjects&nbsp;</span></div><div><br /></div>" },];;

const services = [{icon:"fi fi-br-c",title:"Curriculum",content:"<p class=\"MsoNormal\"><b><span>CURRICULUM<o:p /></span></b></p><p class=\"MsoNormal\"><span><o:p>&nbsp;</o:p></span></p><p class=\"MsoNormal\" style=\"margin-bottom:",link:"../Curriculum",},{icon:"fi fi-br-c",title:"Calendar",content:"<iframe src=\"images/mrcitisrsecschool/Misc/SchoolCalendarcompressed.pdf\" =\"\"=\"\"></iframe>",link:"../Calendar",},{icon:"fi fi-br-l",title:"List Of Books",content:"<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"502\"> <colgroup><col width=\"35\" /> <col width=\"36\" /> <col width=\"245\" /> <col width=\"76\" /> <col width=\"110\" /> </colgroup><tbody><tr height=\"undefined\">",link:"../ListofBooks",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Cbse Affiliated'}
]

const TheAcademicBlock = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Cbse Affiliated"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="TheAcademicBlock"
                subTitle="Cbse Affiliated"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default TheAcademicBlock
