import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo/logowithname.png";
import "./style.scss";
import ScrollToTop from "../../ScrollToTop";
import config from "../../config";
const footerLinks = [
  {
    title: "Quick Links",
    menus: [
      { name: "Home", route: "#" },
      { name: "About", route: "Introduction" },
      { name: "Contact Us", route: "Contact" },
      { name: "Vision \& Mission", route: "VisionMission" },
      { name: "Sports", route: "Sports" },
    ],
  },
  {
    title: "_______",
    menus: [
      { name: "SchoolCodesPolicies", route: "SchoolCodesPolicies" },
      { name: "Guidance & Counselling", route: "GuidanceAndCounselling" },
      { name: "Personal And Social Education", route: "PersonalandSocialEducation" },
      { name: "Cleanliness", route: "Cleanliness" },
      { name: "Public Disclosure", route: "PublicDisclosure" },
    ],
  },

  {
    title: "Contact Us",
    menus: [
      { name: config.Address.schoolName },
      { name: config.Address.street },
      { name: config.Address.city },
      { name: config.Address.phone },
      { name: config.Address.email },
    ],
  },
];
const year = new Date().getFullYear()
const FooterArea = () => {
  return (
    <footer className="footerArea">
      <div className="footerTopArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footerLogo">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
                <p></p>
              </div>
            </div>
            {footerLinks.map((menu, i) => (
              <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                <div className="footerWrap">
                  <h3>{menu.title}</h3>
                  <ScrollToTop />
                  <ul>
                    {menu.menus.map((item, i) => (
                      <li key={i}>
                        {item.route ? (
                          <Link to={`/${item.route}`}>{item.name}</Link>
                        ) : (
                          `${item.name}`
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footerBottomArea">
        <div className="container">
          <div className="footerBottomContent">
            <div className="row">
              <div className="col-md-8 col-sm-10 col-12">
                <span>
                  Privacy Policy | © {year} {config.Address.schoolName}. All rights reserved
                </span>
              </div>
              <div className="col-md-4 col-sm-2 col-12">
                <ul className="socialListFooter">
                  <li>
                    <a
                      target={"_blank"}
                      href="https://www.facebook.com/mrcitischoolblcr"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target={"_blank"}
                      href="https://www.instagram.com/mrcitischoolblcr/"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target={"_blank"}
                      href=""
                    >
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterArea;
