import React, { useState,Fragment } from 'react';
 
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import axios from 'axios';
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import "./style.css"
// Sample options for dropdowns
const GENDER_OPTIONS = ['Male', 'Female', 'Other'];
const BOARD_OPTIONS = ['CBSE', 'ICSE', 'State Board', 'Other'];
const COUNTRY_OPTIONS = ['India', 'USA', 'UK', 'Canada', 'Australia', 'Other'];
const aboutText = [{ text: " " },];;

const services = []
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Apply Online'}
]
 

const Application = () => {
  const [personalDetails, setPersonalDetails] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    mobileNo: '',
    email: '',
    fatherName: '',
    motherName: '',
    spouseName: '',
    gender: '',
    otherGender: '',
    streetAddress: '',
    city: '',
    state: '',
    country: '',
    otherCountry: '',
    postalCode: ''
  });
    
    const [resume, setResume] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [signature, setSignature] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
  const [qualifications, setQualifications] = useState([
    { className: '', school: '', board: '', percentage: '', division: '', otherBoard: '' }
  ]);

  const [experience, setExperience] = useState([
    { organization: '', joiningDate: '', duration: '', position: '', remark: '' }
  ]);

  const [files, setFiles] = useState({ resume: null, photo: null, signature: null });
  const [errors, setErrors] = useState({});

  const handlePersonalDetailsChange = (e) => {
    const { name, value } = e.target;
    setPersonalDetails({ ...personalDetails, [name]: value });
  };

  const handleQualificationsChange = (index, e) => {
    const { name, value } = e.target;
    const updatedQualifications = [...qualifications];
    updatedQualifications[index] = { ...updatedQualifications[index], [name]: value };
    setQualifications(updatedQualifications);
  };

  const handleExperienceChange = (index, e) => {
    const { name, value } = e.target;
    const updatedExperience = [...experience];
    updatedExperience[index] = { ...updatedExperience[index], [name]: value };
    setExperience(updatedExperience);
  };

  const handleFileChange = (e, setter) => {
    setter(e.target.files[0]);
};

  const handleAddQualification = () => {
    setQualifications([...qualifications, { className: '', school: '', board: '', percentage: '', division: '', otherBoard: '' }]);
  };

  const handleRemoveQualification = (index) => {
    setQualifications(qualifications.filter((_, i) => i !== index));
  };

  const handleAddExperience = () => {
    setExperience([...experience, { organization: '', joiningDate: '', duration: '', position: '', remark: '' }]);
  };

  const handleRemoveExperience = (index) => {
    setExperience(experience.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage("Please Wait While...");
    // Validation logic
    let formErrors = {};
    // Add your validation logic here
    if (!personalDetails.gender && personalDetails.gender === 'Other' && !personalDetails.otherGender) {
      formErrors.gender = 'Please specify gender';
    }
    if (!personalDetails.country && personalDetails.country === 'Other' && !personalDetails.otherCountry) {
      formErrors.country = 'Please specify country';
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    const formData = new FormData();
    formData.append('firstName', personalDetails.firstName);
    formData.append('middleName', personalDetails.middleName);
    formData.append('lastName', personalDetails.lastName);
    formData.append('dob', personalDetails.dob);
    formData.append('mobileNo', personalDetails.mobileNo);
    formData.append('email', personalDetails.email);
    formData.append('fatherName', personalDetails.fatherName);
    formData.append('motherName', personalDetails.motherName);
    formData.append('spouseName', personalDetails.spouseName);
    formData.append('gender', personalDetails.gender);
    formData.append('otherGender', personalDetails.otherGender);
    formData.append('streetAddress', personalDetails.streetAddress);
    formData.append('city', personalDetails.city);
    formData.append('state', personalDetails.state);
    formData.append('country', personalDetails.country);
    formData.append('otherCountry', personalDetails.otherCountry);
    formData.append('postalCode', personalDetails.postalCode);

    // Append qualifications
    formData.append('qualificationCount', qualifications.length);
    qualifications.forEach((qualification, index) => {
        formData.append(`qualification[${index}][className]`, qualification.className);
        formData.append(`qualification[${index}][school]`, qualification.school);
        formData.append(`qualification[${index}][board]`, qualification.board);
        formData.append(`qualification[${index}][percentage]`, qualification.percentage);
        formData.append(`qualification[${index}][division]`, qualification.division);
        formData.append(`qualification[${index}][otherBoard]`, qualification.otherBoard);
    });

    // Append experience
    formData.append('experienceCount', experience.length);
    experience.forEach((exp, index) => {
        formData.append(`experience[${index}][organization]`, exp.organization);
        formData.append(`experience[${index}][joiningDate]`, exp.joiningDate);
        formData.append(`experience[${index}][duration]`, exp.duration);
        formData.append(`experience[${index}][position]`, exp.position);
        formData.append(`experience[${index}][remark]`, exp.remark);
    });

    // Append files
    if (resume) formData.append('resume', resume);
    if (photo) formData.append('photo', photo);
    if (signature) formData.append('signature', signature);

    try {
       
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const response = await axios.post('/ApplicationHandler.aspx', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        setResponseMessage(response.data.message || 'Data successfully submitted!');

        setPersonalDetails({
            firstName: '',
            middleName: '',
            lastName: '',
            dob: '',
            mobileNo: '',
            email: '',
            fatherName: '',
            motherName: '',
            spouseName: '',
            gender: '',
            otherGender: '',
            streetAddress: '',
            city: '',
            state: '',
            country: '',
            otherCountry: '',
            postalCode: '',
        });
        setQualifications([]);
        setExperience([]);
        setResume(null);
        setPhoto(null);
        setSignature(null);
    } catch (error) {
        setResponseMessage('Error submitting form: ' + (error.response?.data?.message || error.message));
    }
};
 

  return (
    <Fragment>
    <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
    </header>
    <Breadcumb 
        className="breadcumbArea"
        title="Apply Online"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
    />
    <Service className="serviceArea mt-0" />
    <form onSubmit={handleSubmit}>
      <h3>Personal Details</h3>
      <label>
        First Name:
        <input
          type="text"
          name="firstName"
          value={personalDetails.firstName}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>
      <label>
        Middle Name:
        <input
          type="text"
          name="middleName"
          value={personalDetails.middleName}
          onChange={handlePersonalDetailsChange}
        />
      </label>
      <label>
        Last Name:
        <input
          type="text"
          name="lastName"
          value={personalDetails.lastName}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>
      <label>
        Date of Birth:
        <input
          type="date"
          name="dob"
          value={personalDetails.dob}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>
      <label>
        Mobile No.:
        <input
          type="text"
          name="mobileNo"
          value={personalDetails.mobileNo}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>
      <label>
        Email Id:
        <input
          type="email"
          name="email"
          value={personalDetails.email}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>
      <label>
        Father's Name:
        <input
          type="text"
          name="fatherName"
          value={personalDetails.fatherName}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>
      <label>
        Mother's Name:
        <input
          type="text"
          name="motherName"
          value={personalDetails.motherName}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>
      <label>
        Spouse's Name:
        <input
          type="text"
          name="spouseName"
          value={personalDetails.spouseName}
          onChange={handlePersonalDetailsChange}
        />
      </label>
      <label>
        Gender:
        <select
          name="gender"
          value={personalDetails.gender}
          onChange={handlePersonalDetailsChange}
          required
        >
          <option value="">Select</option>
          {GENDER_OPTIONS.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        {personalDetails.gender === 'Other' && (
          <input
            type="text"
            name="otherGender"
            placeholder="Specify gender"
            value={personalDetails.otherGender}
            onChange={handlePersonalDetailsChange}
          />
        )}
        {errors.gender && <span className="error">{errors.gender}</span>}
      </label>
      <label>
        Street Address:
        <input
          type="text"
          name="streetAddress"
          value={personalDetails.streetAddress}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>
      <label>
        City:
        <input
          type="text"
          name="city"
          value={personalDetails.city}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>
      <label>
        State:
        <input
          type="text"
          name="state"
          value={personalDetails.state}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>
      <label>
        Country:
        <select
          name="country"
          value={personalDetails.country}
          onChange={handlePersonalDetailsChange}
          required
        >
          <option value="">Select</option>
          {COUNTRY_OPTIONS.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        {personalDetails.country === 'Other' && (
          <input
            type="text"
            name="otherCountry"
            placeholder="Specify country"
            value={personalDetails.otherCountry}
            onChange={handlePersonalDetailsChange}
          />
        )}
        {errors.country && <span className="error">{errors.country}</span>}
      </label>
      <label>
        Postal Code:
        <input
          type="text"
          name="postalCode"
          value={personalDetails.postalCode}
          onChange={handlePersonalDetailsChange}
          required
        />
      </label>

      <h3>Qualifications</h3>
      <div className="qualification-grid">
        {qualifications.map((qual, index) => (
          <div key={index}>
            <label>
              Class:
              <input
                type="text"
                name="className"
                value={qual.className}
                onChange={(e) => handleQualificationsChange(index, e)}
                required
              />
              {errors[`qualificationClassName-${index}`] && <span className="error">{errors[`qualificationClassName-${index}`]}</span>}
            </label>
            <label>
              School/College:
              <input
                type="text"
                name="school"
                value={qual.school}
                onChange={(e) => handleQualificationsChange(index, e)}
                required
              />
              {errors[`qualificationSchool-${index}`] && <span className="error">{errors[`qualificationSchool-${index}`]}</span>}
            </label>
            <label>
              Board/University:
              <select
                name="board"
                value={qual.board}
                onChange={(e) => handleQualificationsChange(index, e)}
                required
              >
                <option value="">Select</option>
                {BOARD_OPTIONS.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              {qual.board === 'Other' && (
                <input
                  type="text"
                  name="otherBoard"
                  placeholder="Specify board/university"
                  value={qual.otherBoard}
                  onChange={(e) => handleQualificationsChange(index, e)}
                />
              )}
            </label>
            <label>
              Percentage:
              <input
                type="text"
                name="percentage"
                value={qual.percentage}
                onChange={(e) => handleQualificationsChange(index, e)}
                required
              />
            </label>
            <label>
              Division:
              <input
                type="text"
                name="division"
                value={qual.division}
                onChange={(e) => handleQualificationsChange(index, e)}
                required
              />
            </label>
            <button type="button"  className="form-button-remove" onClick={() => handleRemoveQualification(index)}>Remove</button>
          </div>
        ))}
        <button type="button" className="form-button" onClick={handleAddQualification}>Add Qualification</button>
      </div>

      <h3>Experience</h3>
      <div className="experience-grid">
        {experience.map((exp, index) => (
          <div key={index}>
            <label>
              Organization Name:
              <input
                type="text"
                name="organization"
                value={exp.organization}
                onChange={(e) => handleExperienceChange(index, e)}
                required
              />
              {errors[`experienceOrganization-${index}`] && <span className="error">{errors[`experienceOrganization-${index}`]}</span>}
            </label>
            <label>
              Joining Date:
              <input
                type="date"
                name="joiningDate"
                value={exp.joiningDate}
                onChange={(e) => handleExperienceChange(index, e)}
                required
              />
            </label>
            <label>
              Duration:
              <input
                type="text"
                name="duration"
                value={exp.duration}
                onChange={(e) => handleExperienceChange(index, e)}
                required
              />
            </label>
            <label>
              Position Held:
              <input
                type="text"
                name="position"
                value={exp.position}
                onChange={(e) => handleExperienceChange(index, e)}
                required
              />
            </label>
            <label>
              Remark:
              <input
                type="text"
                name="remark"
                value={exp.remark}
                onChange={(e) => handleExperienceChange(index, e)}
              />
            </label>
            <button type="button" className="form-button-remove" onClick={() => handleRemoveExperience(index)}>Remove</button>
          </div>
        ))}
        <button type="button" className="form-button" onClick={handleAddExperience}>Add Experience</button>
      </div>

      <h3>Upload Documents</h3>
      <label>
        Resume (PDF):
        <input
          type="file"
          name="resume"
          accept=".pdf"
          onChange={(e) => handleFileChange(e, setResume)}
          required
        />
      </label>
      <label>
        Photo (JPG/PNG):
        <input
          type="file"
          name="photo"
          accept=".jpg,.png"
          onChange={(e) => handleFileChange(e, setPhoto)} 
          required
        />
      </label>
      <label>
        Signature (JPG/PNG):
        <input
          type="file"
          name="signature"
          accept=".jpg,.png"
          onChange={(e) => handleFileChange(e, setSignature)}
          required
        />
      </label>

      <h3>Preview</h3>
      <div>
        <h4>Personal Details</h4>
        <p><strong>First Name:</strong> {personalDetails.firstName}</p>
        <p><strong>Middle Name:</strong> {personalDetails.middleName}</p>
        <p><strong>Last Name:</strong> {personalDetails.lastName}</p>
        <p><strong>Date of Birth:</strong> {personalDetails.dob}</p>
        <p><strong>Mobile No.:</strong> {personalDetails.mobileNo}</p>
        <p><strong>Email Id:</strong> {personalDetails.email}</p>
        <p><strong>Father's Name:</strong> {personalDetails.fatherName}</p>
        <p><strong>Mother's Name:</strong> {personalDetails.motherName}</p>
        <p><strong>Spouse's Name:</strong> {personalDetails.spouseName}</p>
        <p><strong>Gender:</strong> {personalDetails.gender} {personalDetails.otherGender && `(${personalDetails.otherGender})`}</p>
        <p><strong>Street Address:</strong> {personalDetails.streetAddress}</p>
        <p><strong>City:</strong> {personalDetails.city}</p>
        <p><strong>State:</strong> {personalDetails.state}</p>
        <p><strong>Country:</strong> {personalDetails.country} {personalDetails.otherCountry && `(${personalDetails.otherCountry})`}</p>
        <p><strong>Postal Code:</strong> {personalDetails.postalCode}</p>

        <h4>Qualifications</h4>
        {qualifications.map((qual, index) => (
          <div key={index}>
            <p><strong>Class:</strong> {qual.className}</p>
            <p><strong>School/College:</strong> {qual.school}</p>
            <p><strong>Board/University:</strong> {qual.board} {qual.otherBoard && `(${qual.otherBoard})`}</p>
            <p><strong>Percentage:</strong> {qual.percentage}</p>
            <p><strong>Division:</strong> {qual.division}</p>
          </div>
        ))}

        <h4>Experience</h4>
        {experience.map((exp, index) => (
          <div key={index}>
            <p><strong>Organization Name:</strong> {exp.organization}</p>
            <p><strong>Joining Date:</strong> {exp.joiningDate}</p>
            <p><strong>Duration:</strong> {exp.duration}</p>
            <p><strong>Position Held:</strong> {exp.position}</p>
            <p><strong>Remark:</strong> {exp.remark}</p>
          </div>
        ))}
  {resume && <a href={URL.createObjectURL(resume)} target="_blank" rel="noopener noreferrer">View Resume</a>}
      
  {photo && <div> <label>Photo Preview</label> <img src={URL.createObjectURL(photo)} alt="Photo Preview" className="preview"  /></div>}
  {signature && <div> <label>Signature Preview</label> <img src={URL.createObjectURL(signature)} alt="Signature Preview" className="preview" /> </div>}
      </div>

      <button className="form-button" type="submit">Submit</button>
    </form>
    {responseMessage && (
    <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <p>{responseMessage}</p>
    </div>
)}
        <FooterArea/>
    </Fragment>
  );
};

export default Application;
