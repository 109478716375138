import React, { Fragment } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import HeroSlider from "../../../components/HeroSlider";
import Service from "../../../components/Service";
import About from "../../../components/About";
// import {heroSliders} from '../global.js';
import ServiceArea from "../../../components/ServiceArea";
import Portfolio from "../../../components/Portfolio";
import Testmonial from "../../../components/Testmonial";
import MessageBoard from '../../../components/MessageBoard'
import ScrollNews from "../../../components/ScrollNews"
import ContactArea from "../../../components/ContactArea";
// import TeamMember from "../../../components/TeamMember";
// import CounterArea from "../../../components/CounterArea";
// import BlogArea from "../../../components/BlogArea";
// import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
// images
import about from "../../../images/about/2.jpg";
import signature from "../../../images/about/1.png";
import config from "../../../config";
// images
import portfolio1 from "../../../images/studies/1.jpg";
import portfolio2 from "../../../images/studies/2.jpg";
import portfolio3 from "../../../images/studies/3.jpg";
import portfolio4 from "../../../images/studies/4.jpg";
import portfolio5 from "../../../images/studies/5.jpg";
const heroSliders = [
  {
    images: "slideWrapperOne",
    title: "of experience & a team of",
    subTitle: "super-talented and specialized teachers.",
    text: "We have accumulated years",
    button: "Contact us now",
    link: "/Contact",
  },
  {  
    images: "slideWrapperTwo",
    title: "Learning Environment:",
    subTitle: "School Administrator Block.",
    text: "Fostering a Safe and Focused",
    button: "Contact us now",
    link: "/Contact",
  },
  {
    images: "slideWrapperThree",
    title: "through Technology",
    subTitle: "Unleash Your Digital Potential",
    text: "Empowering Minds",
    button: "Contact us now",
    link: "/Contact",
  },
  {   
    images: "slideWrapperFour",
    title: "Shaping Tomorrow's World:",
    subTitle: "Our School Laboratory",
    text: "Exploring, Innovating,",
    button: "Contact us now",
    link: "/Contact",
  },
  {  
    images: "slideWrapperFive",
    title: "Unleashing the Potential",
    subTitle: "of Our School Lab",
    text: "Curiosity Meets Discovery:",
    button: "Contact us now",
    link: "/Contact",
  },
];

const services = [
  {
    icon: config.PageMenu.Introduction.icon,
    title: config.PageMenu.Introduction.title,
    content: config.PageMenu.Introduction.content,
    link: config.PageMenu.Introduction.link,
  },
  {
    icon: config.PageMenu.VisionMission.icon,
    title: config.PageMenu.VisionMission.title,
    content: config.PageMenu.VisionMission.content,
    link: config.PageMenu.VisionMission.link,
  },
  {
    icon: config.PageMenu.Sports.icon,
    title: config.PageMenu.Sports.title,
    content: config.PageMenu.Sports.content,
    link: config.PageMenu.Sports.link,
  },
  {
    icon: config.PageMenu.SchoolCodesPolicies.icon,
    title: config.PageMenu.SchoolCodesPolicies.title,
    content: config.PageMenu.SchoolCodesPolicies.content,
    link: config.PageMenu.SchoolCodesPolicies.link,
  },
  {
    icon: config.PageMenu.GuidanceAndCounselling.icon,
    title: config.PageMenu.GuidanceAndCounselling.title,
    content: config.PageMenu.GuidanceAndCounselling.content,
    link: config.PageMenu.GuidanceAndCounselling.link,
  },
  {
    icon: config.PageMenu.PersonalandSocialEducation.icon,
    title: config.PageMenu.PersonalandSocialEducation.title,
    content: config.PageMenu.PersonalandSocialEducation.content,
    link: config.PageMenu.PersonalandSocialEducation.link,
  },
  {
    icon: config.PageMenu.Cleanliness.icon,
    title: config.PageMenu.Cleanliness.title,
    content: config.PageMenu.Cleanliness.content,
    link: config.PageMenu.Cleanliness.link,
  },
  {
    icon: config.PageMenu.SchoolUniform.icon,
    title: config.PageMenu.SchoolUniform.title,
    content: config.PageMenu.SchoolUniform.content,
    link: config.PageMenu.SchoolUniform.link,
  },
  {
    icon: config.PageMenu.Teachers.icon,
    title: config.PageMenu.Teachers.title,
    content: config.PageMenu.Teachers.content,
    link: config.PageMenu.Teachers.link,
  },
];
const aboutText = config.Home;
// const aboutText = [
//   { text: '' },
//   { text: 'Ardent Law firm resulted from an idea to establish a reliable, trustworthy & full-service Law Firm.' },
//   { text: 'At Ardent Law Firm, we have accumulated years of experience & a team of super-talented and specialized lawyers. We listen to our clients\' issues in detail & later offer our tailored solutions to help them out.' },
//   { text: 'We believe in listening & inquiring about every little detail that matters. So when you hire us, you can expect good attention to everything you have to say. On top of it, we will ask the pointed but vital questions for additional information The better you answer them, the better we will be able to offer the solution. Because, ultimately, it\'s your answers that will help us prepare a rock-solid case for you.' },
//   { texth: 'Consult now' },
//   { textc: 'At Ardent, we value our client\'s right to justice above all. And that\'s precisely what has helped us cement our position in the justice system over the years. Whether its Criminal Law, Real Estate Law, Family Law, Business Law or Immigration Law, we have a dedicated team of specialists to assist you.' },
//   { textc: 'In short, we are a full-service Law firm in Ontario that specializes in assisting clients from various sections of society. ' },

// ];

// const portfolioItem = [
//   { images: portfolio1, title: "Criminal Law", subtitle: "Cirminal" },
//   { images: portfolio2, title: "Family Law", subtitle: "Personal Issue" },
//   {
//     images: portfolio3,
//     title: "Legal Matter",
//     subtitle: "Wills/Power of Attorney",
//   },
//   { images: portfolio4, title: "Real Estate Law", subtitle: "Real Estate" },
//   {
//     images: portfolio5,
//     title: "Business Law",
//     subtitle: "Business",
//   },
// ];

const Home = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea" />
      </header>
      <HeroSlider sliders={heroSliders} className="heroSliderArea" />
    
      <Service className="serviceArea" />
      <ScrollNews className="testmonialArea" />
      <MessageBoard
                title="Message"
                subTitle="School Administrators"
                className="teamArea"
                slider={true}
            />
      <About
        className="aboutArea"
        title="About Us"
        subTitle={config.Address.schoolName}
        images={about}
        signature={signature}
        pragraphs={aboutText}
      />
 
      <ServiceArea
        className="ourServiceArea"
        title="How Can We Help You"
        subTitle=" "
        services={services}
      />
  
      {/* <Portfolio
        className="portfolioArea"
        // title="Our Resent Case Studies"
        // subTitle="Here Our Best Work"
        portfolioItem={portfolioItem}
      /> */}
      {/* <Testmonial className="testmonialArea" /> */}
      <ContactArea className="contactArea" />
      {/* <TeamMember
        title="Qualified Attorneys "
        subTitle="Meet Our Experts"
        className="teamArea"
        slider={true}
      />
      <CounterArea className="counterArea" />
      <BlogArea
        className="blogArea"
        title="Latest News"
        subTitle="From Our Blog
                "
      />
      <NewsLetter className="newsLetterArea" /> */}
      <FooterArea />
    </Fragment>
  );
};
export default Home;
