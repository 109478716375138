import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: " " },];;

const services = [{icon:"fi fi-br-f",title:"Fee Structure",content:"<p style=\"margin: 0px; text-align: center;\"><b><u><span style=\"font-size:14.0pt;line-height:107%;font-family:calibri,sans-serif;mso-fareast-font-family:times new roman;color:black;mso-themecolor:text1;mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:en-in;mso-fareast-language:en-in;mso-bidi-language:pa\">School Fee, Schedule &amp; Fee Norms</span></u></b></p><div><span style=\"font-size: 13.3333px;\">SCHOOL FEE</span></div><p class=\"MsoNormal\" style=\"margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;text-align:justify;text-indent:-36.0pt;line-height:normal\"><span s",link:"../FeeStructure",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Norms Followed For Fixing Fee'}
]

const NormsFollowedforFixingFee = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Norms Followed For Fixing Fee"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="Norms Followed For Fixing Fee"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default NormsFollowedforFixingFee
