import React, { Fragment, Component } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "../Pages/Home";
import FamilyLaw from "../Pages/FamilyLaw";
import CivilLitigation from "../Pages/CivilLitigation";
import AboutUs from "../Pages/AboutUs/aboutusPage";
import ImmigrationLaw from "../Pages/ImmigrationLaw";
import BusinessLaw from "../Pages/BusinessLaw";
import CriminalLaw from "../Pages/CriminalLaw";
import RealEstateLaw from "../Pages/RealEstateLaw";
import WillsEstate from "../Pages/WillsEstate";
//import PracticePage from "../Pages/PracticePage";
// import PracticeSinglePage from "../Pages/PracticeSinglePage";
// import PortfolioPage from "../Pages/PortfolioPage";
// import SinglePortfolioPage from "../Pages/SinglePortfolioPage";
import TeamPage from "../Pages/TeamPage";
import SingleTeamPage from "../Pages/SingleTeamPage";
import ContactPage from "../Pages/ContactPage";
import BlogLeftPage from "../Pages/BlogLeftPage";
import BlogRightPage from "../Pages/BlogRightPage";
import BlogFullWidth from "../Pages/BlogFullWidth";
import "./App.css";
import Introduction  from "../Pages/Introduction";
import VisionMission  from "../Pages/VisionMission";
import Chairmandesk  from "../Pages/Chairmandesk";
import DirectorDesk  from "../Pages/DirectorDesk";
import PrincipalDesk  from "../Pages/PrincipalDesk";
import LocationandGuideMap  from "../Pages/LocationandGuideMap";
import Building  from "../Pages/Building";
import Classroom  from "../Pages/Classroom";
import VirtualClassRoom  from "../Pages/VirtualClassRoom";
import Labs  from "../Pages/Labs";
import Library  from "../Pages/Library";
import KnowledgeCentre  from "../Pages/KnowledgeCentre";
import MusicRoom  from "../Pages/MusicRoom";
import DanceRoom  from "../Pages/DanceRoom";
import ArtRoom  from "../Pages/ArtRoom";
import InfirmaryRoom  from "../Pages/InfirmaryRoom";
import PlayStation  from "../Pages/PlayStation";
import ActivityRoom  from "../Pages/ActivityRoom";
import MagicalWorldofChildrenCinema  from "../Pages/MagicalWorldofChildrenCinema";
import Auditorium  from "../Pages/Auditorium";
import SportsCourts  from "../Pages/SportsCourts";
import GymClub  from "../Pages/GymClub";
import IndoorSportsRoom  from "../Pages/IndoorSportsRoom";
import Transport  from "../Pages/Transport";
import POCSOCommittee  from "../Pages/POCSOCommittee";
import GrievanceCommittee  from "../Pages/GrievanceCommittee";
import SexualHarassmentofWomenatWorkplaceCommittee  from "../Pages/SexualHarassmentofWomenatWorkplaceCommittee";
import DisciplinaryCommittee  from "../Pages/DisciplinaryCommittee";
import AntiBullyingCommittee  from "../Pages/AntiBullyingCommittee";
import ClassWiseStrength  from "../Pages/ClassWiseStrength";
import AnnualReport  from "../Pages/AnnualReport";
import ReachUs  from "../Pages/ReachUs";
import TheAcademicBlock  from "../Pages/TheAcademicBlock";
import DetailsofCurriculum  from "../Pages/DetailsofCurriculum";
import SubjectsStreams  from "../Pages/SubjectsStreams";
 
import ListofBooks  from "../Pages/ListofBooks";
import GuidanceAndCounselling  from "../Pages/GuidanceAndCounselling";
import PhysicalWellBeing  from "../Pages/PhysicalWellBeing";
import Cleanliness  from "../Pages/Cleanliness";
import PersonalandSocialEducation  from "../Pages/PersonalandSocialEducation";
import HealthyDietSayNOtoJunkFood  from "../Pages/HealthyDietSayNOtoJunkFood";
import AboutthePrincipal  from "../Pages/AboutthePrincipal";
import Heads  from "../Pages/Heads";
import Teachers  from "../Pages/Teachers";
import TeachersEnrichmentProgramme  from "../Pages/TeachersEnrichmentProgramme";
import EnquiryRegistration  from "../Pages/EnquiryRegistration";
import Admissions  from "../Pages/Admissions";
import FeeStructure  from "../Pages/FeeStructure";
import NormsFollowedforFixingFee  from "../Pages/NormsFollowedforFixingFee";
import SchoolUniform  from "../Pages/SchoolUniform";
import WithdrawalPolicy  from "../Pages/WithdrawalPolicy";
import CareerCounselling  from "../Pages/CareerCounselling";
import SchoolCodesPolicies  from "../Pages/SchoolCodesPolicies";
import AttendanceLeaveRules  from "../Pages/AttendanceLeaveRules";
import SchoolHouseSystem  from "../Pages/SchoolHouseSystem";
import SchoolClubs  from "../Pages/SchoolClubs";
import Senior  from "../Pages/Senior";
import Junior  from "../Pages/Junior";
import DisciplinePolicy  from "../Pages/DisciplinePolicy";
import AntibullingPolicy  from "../Pages/AntibullingPolicy";
import GrievanceCell  from "../Pages/GrievanceCell";
import CBSEResult  from "../Pages/CBSEResult";
import Sports  from "../Pages/Sports";
import ExemplaryPerformances  from "../Pages/ExemplaryPerformances";
import MorningAssembly  from "../Pages/MorningAssembly";
import SpecialAssembly  from "../Pages/SpecialAssembly";
import Sessions  from "../Pages/Sessions";
import EducationaltoursExcursions  from "../Pages/EducationaltoursExcursions";
import AnnualPrizeDistribution  from "../Pages/AnnualPrizeDistribution";
import Album  from "../Pages/Album";
import VideoGallery  from "../Pages/VideoGallery";
import Syllabus  from "../Pages/Syllabus";
import TimeTable  from "../Pages/TimeTable";
import Calendar  from "../Pages/Calendar";
import DateSheet  from "../Pages/DateSheet";
import HolidaysHomeWork  from "../Pages/HolidaysHomeWork";
import StudentProfileUpdationPerfroma  from "../Pages/StudentProfileUpdationPerfroma";
import SchoolLeavingCertificate  from "../Pages/SchoolLeavingCertificate";
import ChangeofAddressPhoneNo  from "../Pages/ChangeofAddressPhoneNo";
import BonafideFeeCertificate  from "../Pages/BonafideFeeCertificate";
import ChangeinTransportFacility  from "../Pages/ChangeinTransportFacility";
import IssueofDuplicateICard  from "../Pages/IssueofDuplicateICard";
import Career  from "../Pages/Career";
import UploadYourResume  from "../Pages/UploadYourResume";
import EnquiryForm  from "../Pages/EnquiryForm";
import RegistrationForm  from "../Pages/RegistrationForm";
import AdmissionForm  from "../Pages/AdmissionForm";
import PaymentOption  from "../Pages/PaymentOption";
import StudentsFeedBackForm  from "../Pages/StudentsFeedBackForm";
import CareerForm  from "../Pages/CareerForm";
import EmployeeLeaveApplicationForm  from "../Pages/EmployeeLeaveApplicationForm";
import TcVerify  from "../Pages/TcVerify";
import Download  from "../Pages/download";
import NoticeBoard from "../Pages/NoticeBoard";
import PublicDisclosure  from "../Pages/PublicDisclosure";
import '../../css/global.css'
import Achievements from "../Pages/Achievements"
 import Application from "../Pages/Application"
class App extends Component {
  render() {
    return (
      <Fragment>
 
        <Router>
          <ToastContainer autoClose={2500} position="top-center" />
          <Routes>
            <Route  path="/"   element={<Home/>} />
            <Route  path="/AboutUs"   element={<AboutUs/>} />
            <Route  path="/FamilyLaw"   element={<FamilyLaw/>} />
            <Route  path="/CivilLitigation"   element={<CivilLitigation/>} />
            <Route  path="/BusinessLaw"   element={<BusinessLaw/>} />
            <Route  path="/CriminalLaw"   element={<CriminalLaw/>} />
            <Route  path="/ImmigrationLaw"   element={<ImmigrationLaw/>} />
            <Route  path="/RealEstateLaw"   element={<RealEstateLaw/>} />
            <Route  path="/WillsEstate"   element={<WillsEstate/>} />
            <Route  path="/Introduction"   element={<Introduction/>} />
            <Route  path="/Achievements"   element={<Achievements/>} />
<Route  path="/VisionMission"   element={<VisionMission/>} />
<Route  path="/Application"   element={<Application/>} />
<Route  path="/Chairmandesk"   element={<Chairmandesk/>} />
<Route  path="/DirectorDesk"   element={<DirectorDesk/>} />
<Route  path="/PrincipalDesk"   element={<PrincipalDesk/>} />
<Route  path="/LocationandGuideMap"   element={<LocationandGuideMap/>} />
<Route  path="/Building"   element={<Building/>} />
<Route  path="/Classroom"   element={<Classroom/>} />
<Route  path="/VirtualClassRoom"   element={<VirtualClassRoom/>} />
<Route  path="/Labs"   element={<Labs/>} />
<Route  path="/Library"   element={<Library/>} />
<Route  path="/KnowledgeCentre"   element={<KnowledgeCentre/>} />
<Route  path="/MusicRoom"   element={<MusicRoom/>} />
<Route  path="/DanceRoom"   element={<DanceRoom/>} />
<Route  path="/ArtRoom"   element={<ArtRoom/>} />
<Route  path="/InfirmaryRoom"   element={<InfirmaryRoom/>} />
<Route  path="/PlayStation"   element={<PlayStation/>} />
<Route  path="/ActivityRoom"   element={<ActivityRoom/>} />
<Route  path="/MagicalWorldofChildrenCinema"   element={<MagicalWorldofChildrenCinema/>} />
<Route  path="/Auditorium"   element={<Auditorium/>} />
<Route  path="/SportsCourts"   element={<SportsCourts/>} />
<Route  path="/GymClub"   element={<GymClub/>} />
<Route  path="/IndoorSportsRoom"   element={<IndoorSportsRoom/>} />
<Route  path="/Transport"   element={<Transport/>} />
<Route  path="/POCSOCommittee"   element={<POCSOCommittee/>} />
<Route  path="/NoticeBoard"   element={<NoticeBoard/>} />
<Route  path="/GrievanceCommittee"   element={<GrievanceCommittee/>} />
<Route  path="/SexualHarassmentofWomenatWorkplaceCommittee"   element={<SexualHarassmentofWomenatWorkplaceCommittee/>} />
<Route  path="/DisciplinaryCommittee"   element={<DisciplinaryCommittee/>} />
<Route  path="/AntiBullyingCommittee"   element={<AntiBullyingCommittee/>} />
<Route  path="/ClassWiseStrength"   element={<ClassWiseStrength/>} />
<Route  path="/AnnualReport"   element={<AnnualReport/>} />
<Route  path="/ReachUs"   element={<ReachUs/>} />
<Route  path="/TheAcademicBlock"   element={<TheAcademicBlock/>} />
<Route  path="/DetailsofCurriculum"   element={<DetailsofCurriculum/>} />
<Route  path="/SubjectsStreams"   element={<SubjectsStreams/>} />
 
<Route  path="/ListofBooks"   element={<ListofBooks/>} />
<Route  path="/GuidanceAndCounselling"   element={<GuidanceAndCounselling/>} />
<Route  path="/PhysicalWellBeing"   element={<PhysicalWellBeing/>} />
<Route  path="/Cleanliness"   element={<Cleanliness/>} />
<Route  path="/PersonalandSocialEducation"   element={<PersonalandSocialEducation/>} />
<Route  path="/HealthyDietSayNOtoJunkFood"   element={<HealthyDietSayNOtoJunkFood/>} />
<Route  path="/AboutthePrincipal"   element={<AboutthePrincipal/>} />
<Route  path="/Heads"   element={<Heads/>} />
<Route  path="/Teachers"   element={<Teachers/>} />
<Route  path="/TeachersEnrichmentProgramme"   element={<TeachersEnrichmentProgramme/>} />
<Route  path="/EnquiryRegistration"   element={<EnquiryRegistration/>} />
<Route  path="/Admissions"   element={<Admissions/>} />
<Route  path="/FeeStructure"   element={<FeeStructure/>} />
<Route  path="/NormsFollowedforFixingFee"   element={<NormsFollowedforFixingFee/>} />
<Route  path="/SchoolUniform"   element={<SchoolUniform/>} />
<Route  path="/WithdrawalPolicy"   element={<WithdrawalPolicy/>} />
<Route  path="/CareerCounselling"   element={<CareerCounselling/>} />
<Route  path="/SchoolCodesPolicies"   element={<SchoolCodesPolicies/>} />
<Route  path="/AttendanceLeaveRules"   element={<AttendanceLeaveRules/>} />
<Route  path="/SchoolHouseSystem"   element={<SchoolHouseSystem/>} />
<Route  path="/SchoolClubs"   element={<SchoolClubs/>} />
<Route  path="/Senior"   element={<Senior/>} />
<Route  path="/Junior"   element={<Junior/>} />
<Route  path="/DisciplinePolicy"   element={<DisciplinePolicy/>} />
<Route  path="/AntibullingPolicy"   element={<AntibullingPolicy/>} />
<Route  path="/GrievanceCell"   element={<GrievanceCell/>} />
<Route  path="/CBSEResult"   element={<CBSEResult/>} />
<Route  path="/Sports"   element={<Sports/>} />
<Route  path="/ExemplaryPerformances"   element={<ExemplaryPerformances/>} />
<Route  path="/MorningAssembly"   element={<MorningAssembly/>} />
<Route  path="/SpecialAssembly"   element={<SpecialAssembly/>} />
<Route  path="/Sessions"   element={<Sessions/>} />
<Route  path="/EducationaltoursExcursions"   element={<EducationaltoursExcursions/>} />
<Route  path="/AnnualPrizeDistribution"   element={<AnnualPrizeDistribution/>} />
<Route  path="/Album"   element={<Album/>} />
<Route  path="/VideoGallery"   element={<VideoGallery/>} />
<Route  path="/Syllabus"   element={<Syllabus/>} />
<Route  path="/TimeTable"   element={<TimeTable/>} />
<Route  path="/Calendar"   element={<Calendar/>} />
<Route  path="/DateSheet"   element={<DateSheet/>} />
<Route  path="/HolidaysHomeWork"   element={<HolidaysHomeWork/>} />
<Route  path="/StudentProfileUpdationPerfroma"   element={<StudentProfileUpdationPerfroma/>} />
<Route  path="/SchoolLeavingCertificate"   element={<SchoolLeavingCertificate/>} />
<Route  path="/ChangeofAddressPhoneNo"   element={<ChangeofAddressPhoneNo/>} />
<Route  path="/BonafideFeeCertificate"   element={<BonafideFeeCertificate/>} />
<Route  path="/ChangeinTransportFacility"   element={<ChangeinTransportFacility/>} />
<Route  path="/IssueofDuplicateICard"   element={<IssueofDuplicateICard/>} />
<Route  path="/Career"   element={<Career/>} />
<Route  path="/UploadYourResume"   element={<UploadYourResume/>} />
<Route  path="/EnquiryForm"   element={<EnquiryForm/>} />
<Route  path="/RegistrationForm"   element={<RegistrationForm/>} />
<Route  path="/AdmissionForm"   element={<AdmissionForm/>} />
<Route  path="/PaymentOption"   element={<PaymentOption/>} />
<Route  path="/StudentsFeedBackForm"   element={<StudentsFeedBackForm/>} />
<Route  path="/CareerForm"   element={<CareerForm/>} />
<Route  path="/EmployeeLeaveApplicationForm"   element={<EmployeeLeaveApplicationForm/>} />
<Route  path="/TcVerify"   element={<TcVerify/>} />
<Route  path="/Download"   element={<Download/>} />
<Route  path="/PublicDisclosure"   element={<PublicDisclosure/>} />
            {/* <Route
               
              path="/practice-details"
              component={PracticeSinglePage}
            />
            <Route   path="/case-stadies" component={PortfolioPage} />
            <Route
               
              path="/case-stadies-details"
              component={SinglePortfolioPage}
            /> */}
            <Route  path="/attorneys"   element={<TeamPage/>} />
            <Route  path="/attorneys-single"   element={<SingleTeamPage/>} />
            <Route  path="/contact"   element={<ContactPage/>} />
            <Route  path="/blog-left"   element={<BlogLeftPage/>} />
            <Route  path="/blog-right"   element={<BlogRightPage/>} />
            <Route  path="/blog-fullwidth"   element={<BlogFullWidth/>} />
          </Routes>
        </Router>
      </Fragment>
    );
  }
}

export default App;
