import React, { useState, useEffect, Fragment } from 'react';
import HeaderBotton from '../../../components/HeaderBottom';
import HeaderTop from '../../../components/HeaderTop';
import Breadcumb from '../../../components/Breadcumb';
import Service from '../../../components/Service';
import About from '../../../components/AboutWithoutImage';
import ServiceArea from '../../../components/ServiceArea';
import FooterArea from '../../../components/FooterArea';
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg';
import breadcumb from '../../../images/breadcumb/1.jpg';
import backButtonImg from '../../../images/folder-parent.png'; // Import the back button image
import Modal from '../../../components/Modal'; // Import Modal component
import './style.css';

const aboutText = [{ text: "" }];

const services = [
  // Your services data here...
];

const breadcumbMenu = [
  { name: 'Home', route: '/' },
  { name: 'Achievements' }
];

function Achievements() {
  const [data, setData] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedSubFolder, setSelectedSubFolder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState('');

  // Fetch the achievement.txt file
  useEffect(() => {
    fetch('/source/achievement.txt')
      .then((response) => response.json())
      .then((data) => setData(data.subFolders || [])) // Only use subfolders, skipping the root "Achievements"
      .catch((error) => console.error('Error fetching the data:', error));
  }, []);

  const handleFolderClick = (folder) => {
    setSelectedFolder(folder);
    setSelectedSubFolder(null); // Reset subfolder selection when a new folder is clicked
  };

  const handleSubFolderClick = (subFolder) => {
    setSelectedSubFolder(subFolder);
  };

  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImageSrc('');
  };

  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Achievements"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />
      <Service className="serviceArea mt-0" />
      <About
        className="aboutArea aboutAreaStyleTwo"
        title="About the Principal"
        subTitle="Achievements"
        images={immigrationLaw}
        orderLast="order-last"
        videoId="XxVg_s8xAms"
        pragraphs={aboutText}
      />
  
      <div className="folder-container">
        {selectedFolder ? (
          selectedSubFolder ? (
            <div>
              <div className="back-button" onClick={() => setSelectedSubFolder(null)}>
                <img src={backButtonImg} alt="Back" />
                <span>Back</span>
              </div>
              <h2>{selectedSubFolder.folderName}</h2>
              <div className="images">
                {selectedSubFolder.files?.map((file, index) => (
                  <img
                    key={index}
                    src={`/images/mrcitisrsecschool/Achievements/${selectedFolder.folderName}/${selectedSubFolder.folderName}/${file}`}
                    alt={file}
                    onClick={() => openModal(`/images/mrcitisrsecschool/Achievements/${selectedFolder.folderName}/${selectedSubFolder.folderName}/${file}`)}
                    style={{ width: '200px', margin: '10px', cursor: 'pointer' }}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div>
              <div className="back-button" onClick={() => setSelectedFolder(null)}>
                <img src={backButtonImg} alt="Back" />
                <span>Back</span>
              </div>
              <h2>{selectedFolder.folderName}</h2>
              {selectedFolder.subFolders ? (
                <div className="folder-container">
                  {selectedFolder.subFolders.map((subFolder, index) => (
                    <div
                      key={index}
                      className="folder"
                      onClick={() => handleSubFolderClick(subFolder)}
                    >
                      <div className="folder-icon"></div>
                      <div className="folder-label">{subFolder.folderName}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="images">
                  {selectedFolder.files?.map((file, index) => (
                    <img
                      key={index}
                      src={`/images/${selectedFolder.folderName}/${file}`}
                      alt={file}
                      onClick={() => openModal(`/images/${selectedFolder.folderName}/${file}`)}
                      style={{ width: '200px', margin: '10px', cursor: 'pointer' }}
                    />
                  ))}
                </div>
              )}
            </div>
          )
        ) : (
          <div className="folder-container">
            {data.map((folder, index) => (
              <div
                key={index}
                className="folder"
                onClick={() => handleFolderClick(folder)}
              >
                <div className="folder-icon"></div>
                <div className="folder-label">{folder.folderName}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <FooterArea />
      <Modal isOpen={modalOpen} imageSrc={modalImageSrc} onClose={closeModal} />
    </Fragment>
  );
}

export default Achievements;
