import React, { useState, useEffect } from "react";
import "react-modal-video/scss/modal-video.scss";
import "./style.scss";

const About = ({
  subTitle,
  title,
  className,
  pragraphs,
  pageName,
}) => {
  const [htmlContent, setHtmlContent] = useState([]);

  // Function to fetch HTML content from the text file based on title
  async function fetchHtmlFromFile(title) {
    try {
      const url = `/source/${title}.txt`; // Adjust this path if needed
      const response = await fetch(url);
      
      if (!response.ok) { 
        if (response.status === 404) {
          throw new Error('404 - File not found');
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      const html = await response.text();
      return html;
    } catch (error) {
      console.error('Error fetching HTML:', error);
      return null;
    }
  }

  useEffect(() => {
    async function updateContent() {
      if (title) {
        // Fetch HTML content only if title is not null or empty
        const fetchedHtml = await fetchHtmlFromFile(title);

        // Update the HTML content if fetched successfully, otherwise use default text
        if (fetchedHtml) {
          setHtmlContent(pragraphs.map(() => fetchedHtml));
        } else {
          setHtmlContent(pragraphs.map(pragraph => pragraph.text));
        }
      } else {
        // If title is null or empty, use default paragraph text
        setHtmlContent(pragraphs.map(pragraph => pragraph.text));
      }
    }

    updateContent();
  }, [title]); // Depend only on `title` to ensure this effect runs only when `title` changes

  const headerStyle = {
    color: "white !important",
    backgroundColor: "gray",
    display: "table",
    padding: "5px auto !important",
  };

  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="aboutContent">
              <center>
              {subTitle === "" ? "" : (
                <span style={headerStyle}>
                  <h1>
                    <b>{subTitle}</b>
                  </h1>
                </span>
              )}
              </center>

              {pragraphs.map((pragraph, i) => (
                <p key={i} style={{ backgroundColor: "white" }}>
                  <div
                    dangerouslySetInnerHTML={{ __html: htmlContent[i] || pragraph.text }}
                  />
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
