import React, { Fragment } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
import Form from "../../../components/Form";
// images
import breadcumb from "../../../images/breadcumb/1.jpg";
import config from "../../../config";
import Map from "../../../components/Map"
import "./style.scss";
import About from '../../../components/AboutWithoutImage'
const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Contact" }];
const aboutText = [{ text: ""}];
const ContactPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Contact Us"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />

      <div className="contactusPageArea">
        <div className="container">
 
          <div className="row">
            <div className="col-lg-5">
              <div className="contactUsInfo">
                <h3>Our Contacts</h3>

                <h4>Address</h4>
                <span>{config.Address.street + "," + config.Address.city}</span>
                <h4>Phone</h4>
                <span>{config.Address.phoneInternational}</span>
                {/* <span>+1 (905) 795-5500</span> */}
                <h4>Email</h4>
                <span>{config.Address.email}</span>
                <About
                className="aboutArea aboutAreaStyleTwo"
                title="ReachUs"
                subTitle=""
               
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            
              </div>
            
            </div>
            <div className="col-lg-7">
              <div className="contactUSForm">
                <h3>Quick Contact Form</h3>
                <Form addressInfo={true} />
              </div>
            </div>
            <Map/>
          </div>
        </div>
      </div>

      <NewsLetter className="newsLetterArea" />
      <FooterArea />
    </Fragment>
  );
};
export default ContactPage;
