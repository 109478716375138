import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: " " },];;

const services = [{icon:"fi fi-br-s",title:"School Codes & Policies",content:"<br /><div><span style=\"font-size: 13.3333px;\">Rules &amp; Regulations for Students</span></div><div><span style=\"font-size: 13.3333px;\">To Maintain strict discipline to speed up progress and efficiency we are compelled to take certain precautionary measures in the school.</span></div><div><span style=\"font-size: 13.3333px;\"><br /></span></div><div><span style=\"font-size: 13.3333px;\">Students are not allowed to bring Mobile Phone in School Campus. In case they bring it, they must deposit it in the Principal Office. If they are found with Mobile Phone in School campus they shall be fi",link:"../SchoolCodesPolicies",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Grievance Cell'}
]

const GrievanceCell = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Grievance Cell"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="Grievance Cell"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default GrievanceCell
