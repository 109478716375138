import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: " " },];;

const services = [{icon:"fi fi-br-s",title:"School House System",content:"<div><div><span style=\"font-size: 13.3333px;\"><br />The students are divided into four houses: Subhash, Tilak, Patel and Tagore-each representing an inner emotion of a growing child. Each house is headed by a captain and vice-captain who take constant guidance from House teachers. Inter House competitions are organized in various cultural and co-curricular activities. This fosters a spirit of cooperation and healthy competition amongst the pupils. All the four houses are actively involved in the smooth and systematic functioning of the school. Each house remains on duty for one week to loo",link:"../SchoolHouseSystem",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'School Clubs'}
]

const SchoolClubs = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="School Clubs"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="School Clubs"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default SchoolClubs
