import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: "<div><span><br /><b>Sports Courts</b></span></div><br /><br /><span>Sports courts for various games just as volleyball, Basketball, badminton, Cricket, Kho-Kho are designed so as to provide ample opportunities to students in sports field.<br /><br /></span><span lang=\"EN-US\">Open gym facilities are provided to students so as to provide students fitness which enhances their self-worth, mood, sleep energy and lowering risk of stress &amp; clinical depression etc</span><span>.&nbsp;</span><br /><br /><div><img src=\"images/mrcitisrsecschool/Misc/OutdoorSports.PNG\" /></div> <div><span><br /><b>Indoor Sports Room</b></span></div><br /><br /><span>Indoor Sports Room are developed to provide training to students for games like chess, carrom etc.&nbsp;</span> <div><span><br /><br /><div><img src=\"images/mrcitisrsecschool/Misc/Indoorgames.JPG\" /></div><b>Gym Club</b></span></div><br /><br /><span>Sports gym for junior kids is specially designed to teach them balancing skills required for physical development in them.</span>" },];;

const services = []
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Sports Courts'}
]

const SportsCourts = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Sports Courts"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="SportsCourts"
                subTitle="Sports Courts"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default SportsCourts
