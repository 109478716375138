import React, { useState, useEffect } from 'react';
import './PDFViewer.css'; // Import the CSS file

const PDFViewer = ({ directoryName, jsonFileName }) => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [error, setError] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");

  // Function to fetch the list of files from the JSON URL
  async function fetchPDFFiles() {
    try {
      const jsonUrl = `/source/${jsonFileName}`;
      console.log(`Fetching JSON from: ${jsonUrl}`);
      const response = await fetch(jsonUrl);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched file data:', data);

      // Filter out files that do not exist
      const validFiles = await Promise.all(data.map(async item => {
        const fileUrl = `/${directoryName}/${item.actFile}`;
        const fileExists = await checkFileExists(fileUrl);
        console.log(`Checking file: ${fileUrl}, Exists: ${fileExists}`);
        return fileExists ? { displayName: item.dispFile, actualName: item.actFile } : null;
      }));

      // Filter out null entries and update state
      const filteredFiles = validFiles.filter(file => file !== null);

      if (filteredFiles.length > 0) {
        setFiles(filteredFiles);
        setSelectedFile(filteredFiles[0].actualName); // Set the first file as default
        setPdfUrl(`/${directoryName}/${filteredFiles[0].actualName}`); // Set the default PDF URL
        setError(null);
      } else {
        setFiles([]);
        setSelectedFile(""); // Clear selected file
        setPdfUrl(""); // Clear PDF URL
        setError('No valid PDF files available.');
      }
    } catch (error) {
      console.error("Error fetching files:", error);
      setFiles([]);
      setSelectedFile(""); // Clear selected file
      setPdfUrl(""); // Clear PDF URL
      setError('Error fetching files.');
    }
  }

  // Function to check if a file exists
  async function checkFileExists(url) {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if (!response.ok) {
        // Handle HTTP errors
        console.error(`File check failed for ${url}, status: ${response.status}`);
        return false;
      }

      // HEAD requests typically do not have a body
      return response.status === 200;
    } catch (error) {
      console.error('Error checking file existence:', error);
      return false;
    }
  }

  useEffect(() => {
    if (jsonFileName) {
      fetchPDFFiles();
    }
  }, [directoryName, jsonFileName]);

  const handleChange = (event) => {
    const newSelectedFile = event.target.value;
    setSelectedFile(newSelectedFile);
    setPdfUrl(`/${directoryName}/${newSelectedFile}`); // Update PDF URL on selection change
  };

  return (
    <div className="pdf-viewer">
      {error ? (
        <p>{error}</p>
      ) : files.length > 0 ? (
        <>
          <div className="controls">
            <label htmlFor="fileSelector">Select a PDF file:</label>
            <select id="fileSelector" value={selectedFile} onChange={handleChange}>
              {files.map((file, index) => (
                <option key={index} value={file.actualName}>
                  {file.displayName}
                </option>
              ))}
            </select>
            <button className="download-button" onClick={() => window.location.href = pdfUrl}>
              Download PDF
            </button>
          </div>

          {pdfUrl && (
            <div className="pdf-viewer-content">
              <h2>{files.find(file => file.actualName === selectedFile)?.displayName}</h2>
              <embed
                src={pdfUrl}
                className="pdf-embed"
                type="application/pdf"
              />
            </div>
          )}
        </>
      ) : (
        <div className="default-page">
          <p>No PDF files available.</p>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
