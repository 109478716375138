import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: " " },];;

const services = [{icon:"fi fi-br-a",title:"Admissions",content:"<br /><div><span>Admission Procedure</span></div><div><span><br /></span></div><div><span>Admission is open to all sections of society, irrespective of caste, creed of social status. The session of school is from April to March. Registration begins in the December month. The Registration and Admission forms can be obtained from the school administrative office from admission in-charge on all working days.</span></div><div><span><br /></span></div><div><span styl",link:"../Admissions",},{icon:"fi fi-br-f",title:"Fee Structure & Norms",content:"<p><b><u><span>School Fee, Schedule &amp; Fee Norms</span></u></b></p><div><span>SCHOOL FEE</span></div><p class=\"MsoNormal\"><span>1.&nbsp; &nbsp; &nbsp; School Fee is payable in advance bi-monthly in HDFC Bank, Balachaur/ Capital Small Finance Bank, Balachaur/ ICIC",link:"../FeeStructureNorms",},{icon:"fi fi-br-s",title:"School Uniform",content:"<br /><p class=\"MsoNormal\"><b><span><o:p>&nbsp;</o:p></span></b></p><p class=\"MsoNormal\" align=\"center\"><b>SCHOOL UNIFORM<o:p /></b></p><p class=\"MsoNormal\">On all working days and at school functions, chil",link:"../SchoolUniform",},{icon:"fi fi-br-w",title:"Withdrawal Policy",content:"<br /><div><span>1.If fees and other dues are not paid after the issuance of one reminder, the name of the student is struck off the rolls.</span></div><div><span>2.If the conduct, behaviour or influence of a student is detrimental to the general discipline and interest of the Institute, the Principal may order his/her suspension / withdrawal.</span></div><div><span>3.If a student is found medically unfit and is a health hazard to other students, he/she may be asked to withdraw.</span></div><div><",link:"../WithdrawalPolicy",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Enquiry & Registration'}
]

const EnquiryRegistration = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Enquiry & Registration"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="EnquiryRegistration"
                subTitle="Enquiry & Registration"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default EnquiryRegistration
