import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

const services = [
  { icon: "fi-rr-camera-viewfinder", subtitle: "Online T.C. verification", title: "Verify T.C.",link:"../TcVerify" },
  { icon: "fi-rr-calendar-image", subtitle: "Click here to view latest information", title: "Notice Board",link:"../NoticeBoard" },
  { icon: "fi flaticon-alumni", subtitle: "MRCITI Alumni", title: "Alumni",link:"https://alumni.mrcitisrsecschool.co.in/",target:"_blank"  },
];
const Service = (props) => {
  return (
    <div className={props.className}>
         
      <div className="container">
 
        <div className="row jconten">
          {services.map((service, i) => (
            <div key={i} className="col-lg-4 col-md-6">
            
              <div className="serviceItem">
                <div className="serviceIcon">
                  <i className={service.icon}></i>
                </div>
                {service.target=="_blank"?
                <a href={service.link} target="_blank" rel="noopener noreferrer">
                <div className="serviceText">
                  <span>{service.subtitle}</span>
                  <h3>{service.title}</h3>
                </div>
              </a>
                : 
                <Link  to={service.link}> 
                <div className="serviceText">
                  <span>{service.subtitle}</span>
                  <h3>{service.title}</h3>
                </div>
                </Link>
}
                </div>
              
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Service;
