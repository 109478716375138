import React, { Fragment,useState  } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
 
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: " " },];

const services = []
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Verify T.C.'}
]

const TcVerify = () => {
    const [tcNo, setTcNo] = useState('');
    const [tcDate, setTcDate] = useState('');
    const [dob, setDob] = useState('');
    const [verificationStatus, setVerificationStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const domainName = window.location.origin;
    const handleVerify = async () => {
      if (!tcNo || !tcDate || !dob) {
        setVerificationStatus('All fields are mandatory.');
        return;
      }
  
      setIsLoading(true);
      setVerificationStatus('Verifying...');
  
      try {
        const response = await fetch(
          `${domainName}//TcVerify.aspx?tcNo=${encodeURIComponent(tcNo)}&TcDate=${encodeURIComponent(tcDate)}&dob=${encodeURIComponent(dob)}`
        );
        const data = await response.text();
        setVerificationStatus(data);
      } catch (error) {
        setVerificationStatus('An error occurred while verifying. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Verify T.C."
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="TcVerify"
                subTitle="Verify T.C."
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />

<div style={{ padding: '5px', maxWidth: '400px', margin: '0 auto' }}>
      {/* <h1>Online Automated Transfer Certificate Verification</h1> */}
      <div style={{ marginBottom: '10px' }}>
        <label>
          Transfer Certificate No.:
          <input
            type="text"
            value={tcNo}
            onChange={(e) => setTcNo(e.target.value)}
            placeholder="Enter TC No."
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label>
          Transfer Certificate Date (MM/DD/YYYY):
          <input
            type="date"
            value={tcDate}
            onChange={(e) => setTcDate(e.target.value)}
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label>
          Student Date Of Birth (MM/DD/YYYY):
          <input
            type="date"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
        </label>
      </div>
      <button
        onClick={handleVerify}
        style={{
          width: '100%',
          padding: '10px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          cursor: 'pointer'
        }}
        disabled={isLoading}
      >
        {isLoading ? 'Verifying...' : 'Verify Transfer Certificate'}
      </button>
    
    </div>
    {verificationStatus && (
         <div
         style={{ marginTop: '20px' }}
         dangerouslySetInnerHTML={{ __html: verificationStatus }}
       />
      )}
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default TcVerify
