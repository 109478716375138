import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: "<div><span><br /><br /></span></div><p class=\"MsoNormal\"><span>Morning and Special </span><b><span>Assembly<o:p /></span></b></p><p class=\"MsoNormal\"><b><span>&nbsp;</span></b></p><p class=\"MsoNormal\"><b><span>&nbsp;</span></b></p><p class=\"MsoNormal\"><span>We value individualism andtry to inculcate creativity, innovation and confidence in order to achievehundred percent participation of the student. We have organically unique formof \'Assemblies wherein we help the students to refine their oratory skills.Develop confidence of public speaking and eschew the stage fear.<o:p /></span></p><p class=\"MsoNormal\"><span>&nbsp;</span></p><p class=\"MsoNormal\"><span>To inculcate moral values in the students the schoolstarts each working day with morning assembly, that includes recitation ofGayatri Mantra, Prayer, thought of the day, major news headlines of the day,birthday song for the students. Stress is laid on the universal aspects andeternal values of Indian culture. Delivering of short speeches and conductingof G.K. Quiz on variety of subjects and important current events is done by thestudents.<o:p /></span></p><p class=\"MsoNormal\"><span>&nbsp;</span></p><p class=\"MsoNormal\"><span>The school\'s stress is always on balanced blend oflearner\'s brain. We at M.R. Citi conduct pre-examination special morningassembly every year to somehow free the student from the everyday stress andstrain.<o:p /></span></p><p class=\"MsoNormal\"><span>&nbsp;</span></p><p><span>Every morning the assembly disperses with the singing ofthe national anthem.</span></p><p> <img src=\"images/mrcitisrsecschool/Misc/01Final.JPG\" /></p>" },];;

const services = []
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Morning Assembly'}
]

const MorningAssembly = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Morning Assembly"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="MorningAssembly"
                subTitle="Morning Assembly"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default MorningAssembly
