import React from "react";
import GoogleMapReact from 'google-map-react';
import Marker from './Marker.tsx';
 import config from "../../config.js"

export default function SimpleMap(){
  const defaultProps = {
    center: {
      lat: 31.075462954174235,
      lng: 76.31783823861775
    },
    zoom: 16
  };
 // 43.673582591297176, -79.67667448935622
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDxvf0vP4t61M5aDbA2Q4bzhn19R0J3fyo" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
      >
   <Marker 
            lat={31.075462954174235}
            lng={76.31783823861775}
            name={config.Address.schoolName}
            color="red"
          />
      </GoogleMapReact>
    </div>
  );
}

// import React from "react";
// import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

// export default function Map(){
//   const defaultProps = {
//     center: {
//       lat: 10.99835602,
//       lng: 77.01502627
//     },
//     zoom: 11
//   };

//   return (
//     // Important! Always set the container height explicitly
//     <div style={{ height: '100vh', width: '100vw' }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: "AIzaSyDxvf0vP4t61M5aDbA2Q4bzhn19R0J3fyo" ,language: 'en'}}
//         defaultCenter={defaultProps.center}
//         defaultZoom={defaultProps.zoom}
//       >
//         <AnyReactComponent
//           lat={59.955413}
//           lng={30.337844}
//           text="My Marker"
//         />
//       </GoogleMapReact>
//     </div>
//   );
// }