import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: "<img src=\"images/mrcitisrsecschool/Misc/001.jpg\" /><img src=\"images/mrcitisrsecschool/Misc/Tour.jpg\" /><img src=\"images/mrcitisrsecschool/Misc/Tour_1.jpg\" /><img src=\"images/mrcitisrsecschool/Misc/Tour_2.jpg\" />" },];;

const services = [{icon:"fi fi-br-a",title:"Annual Prize Distribution",content:"<img src=\"images/mrcitisrsecschool/Misc/Annual%20Function(9).JPG\" /><img src=\"images/mrcitisrsecschool/Misc/Annual%20Function(10).JPG\" />",link:"../AnnualPrizeDistribution",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Educational Tours & Excursions'}
]

const EducationaltoursExcursions = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Educational Tours & Excursions"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="EducationaltoursExcursions"
                subTitle="Educational Tours & Excursions"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default EducationaltoursExcursions
