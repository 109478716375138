import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: " " },];;

const services = [{icon:"fi fi-br-s",title:"School Codes & Policies",content:"<p class=\"MsoNormal\"><span lang=\"EN-US\">SchoolCodes &amp; Policies</span></p><div><span>Rules &amp; Regulations for Students<br /><br /></span></div><div><span>To Maintain strict discipline to speed up progress and efficiency we are compelled to take certain precautionary measures in the school.</span></div><div><span><br /></span></div><div><span style=\"fo",link:"../SchoolCodesPolicies",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Discipline Policy'}
]

const DisciplinePolicy = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Discipline Policy"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="DisciplinePolicy"
                subTitle="Discipline Policy"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default DisciplinePolicy
