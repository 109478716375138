import React, { useState, useEffect, useRef, Fragment } from "react";
import HeaderBotton from '../../../components/HeaderBottom';
import HeaderTop from '../../../components/HeaderTop';
import Breadcumb from '../../../components/Breadcumb';
import Service from '../../../components/Service';
import FooterArea from '../../../components/FooterArea';
import breadcumb from '../../../images/breadcumb/1.jpg';

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Notice Board' }
];

const NoticeBoard = () => {
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const newsListRef = useRef(null); // Ref to the news list

  useEffect(() => {
    fetch("/source/NoticeBoard.txt")
      .then((response) => response.json())
      .then((data) => {
        setNewsData(data);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err.message);
      });
  }, []);

  useEffect(() => {
    // Scroll to the first notice on the page when currentPage changes
    if (newsListRef.current) {
      newsListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  // Get current news items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = newsData.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb 
        className="breadcumbArea"
        title="Notice Board"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />
      <Service className="serviceArea mt-0" />
 
      <div className="newsList">
        <div ref={newsListRef} className="newsItems"> {/* Apply the ref here */}
          {currentItems.map((news, index) => (
            <div key={index} className="newsItem">
              <h4>{news.title}</h4>
              <h5>{news.subTitle}</h5>
              <img src={news.images} alt={news.title} />
              <p dangerouslySetInnerHTML={{ __html: news.text }} />
            </div>
          ))}
        </div>
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={newsData.length}
          paginate={paginate}
        />
      </div>
      <FooterArea />
    </Fragment>
  );
};

const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <button
              onClick={(e) => {
                e.preventDefault();
                paginate(number);
              }}
              className="page-link"
              style={{
                margin: "10px 5px",
                borderRadius: "5px",
                backgroundColor: "#f0f0f0",
                border: "1px solid #ddd",
                color: "#333",
                cursor: "pointer",
              }}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NoticeBoard;
