import React, { useEffect, useState } from 'react';

// Utility function to extract video ID from various YouTube URL formats
const extractVideoId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|watch|embed)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const VideosGallery = () => {
  const [videoLinks, setVideoLinks] = useState([]);

  useEffect(() => {
    const fetchSlideshowData = async () => {
      try {
        const response = await fetch('/source/video.txt');
        const data = await response.text(); // Use text() instead of json()
        
        // Split data into lines and filter empty lines
        const linksArray = data.split('\n').map(link => link.trim()).filter(link => link);

        // Set state with valid video IDs
        const videoIds = linksArray.map(extractVideoId).filter(id => id);
        setVideoLinks(videoIds);
      } catch (error) {
        console.error('Error fetching slideshow data:', error);
      }
    };

    fetchSlideshowData();
  }, []);

  return (
    <div style={styles.gallery}>
      {videoLinks.map((id) => (
        <div key={id} style={styles.videoWrapper}>
          <iframe
            width="320"
            height="180"
            src={`https://www.youtube.com/embed/${id}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ))}
    </div>
  );
};

// Styles
const styles = {
  gallery: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    margin: '20px',
  },
  videoWrapper: {
    margin: '10px',
  },
};

export default VideosGallery;
