import React, { useState, useEffect } from 'react';
import './style.css';

const Slideshow = () => {
  const [path, setPath] = useState([]); // Track folder navigation path
  const [currentFolder, setCurrentFolder] = useState(null); // Currently viewed folder
  const [images, setImages] = useState([]); // Images in the current folder
  const [currentSlide, setCurrentSlide] = useState(0); // Current slide index
  const [isModalOpen, setIsModalOpen] = useState(false); // Track modal open state
  const [caption, setCaption] = useState(''); // Caption for the current image

  // Fetch the slideshow data and set initial folder structure
  useEffect(() => {
    const fetchSlideshowData = async () => {
      try {
        const response = await fetch('/source/PhotoGallery.txt');
        const data = await response.json();
        setCurrentFolder(data); // Set the fetched data as the current folder
        setImages(extractImages(data, '/images')); // Extract images based on the root folder
      } catch (error) {
        console.error('Error fetching slideshow data:', error);
      }
    };

    fetchSlideshowData();
  }, []);

  // Function to extract image URLs from a folder object
  const extractImages = (folder, basePath) => {
    if (!folder.files || folder.files.length === 0) {
      console.log('No files found in folder:', folder.folderName);
      return [];
    }

    // Construct the full path for the current folder
    const fullBasePath = `${basePath}/${folder.folderName}`;
    
    // Build the full image paths using the folder's full path
    const imageUrls = folder.files.map(file => `${fullBasePath}/${file}`);
    console.log('Extracted image URLs from folder:', folder.folderName, imageUrls);
    return imageUrls;
  };

  // Navigate to a subfolder and keep track of the path
  const navigateToFolder = (subFolder) => {
    // Add the current folder to the path before navigating
    setPath((prevPath) => [...prevPath, currentFolder]);
    setCurrentFolder(subFolder); // Set the clicked subfolder as the current folder
  };

  // Navigate back to the previous folder
  const navigateBack = () => {
    const previousFolder = path[path.length - 1];
    if (previousFolder) {
      setCurrentFolder(previousFolder); // Set to the last folder in the path
      setImages(extractImages(previousFolder, '/images/mrcitisrsecschool'));
      setPath(path.slice(0, -1)); // Remove last folder from path
      setCurrentSlide(0); // Reset slide index
    }
  };

  // Update the images whenever the current folder changes
  useEffect(() => {
    if (currentFolder) {
      const fullPath = path.reduce((acc, folder) => `${acc}/${folder.folderName}`, '/images/mrcitisrsecschool');
      setImages(extractImages(currentFolder, fullPath)); // Build full path dynamically
    }
  }, [currentFolder, path]);

  // Navigate to the next slide
  const nextSlide = () => setCurrentSlide((currentSlide + 1) % images.length);

  // Navigate to the previous slide
  const prevSlide = () => setCurrentSlide((currentSlide - 1 + images.length) % images.length);

  // Open the modal with the full image
  const openModal = () => {
    setIsModalOpen(true);
    const fullImagePath = images[currentSlide];
    const filenameWithExtension = fullImagePath.split('/').pop(); // Get the filename with extension
    const filenameWithoutExtension = filenameWithExtension.split('.').slice(0, -1).join('.'); // Remove the extension
    setCaption(filenameWithoutExtension); // Set the caption without extension
  };

  // Close the modal
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="slideshow-center-container">
      <div className="slideshow-container">
        <h1 className="slideshow-header">{currentFolder ? currentFolder.folderName : 'Loading...'}</h1>

        {/* Back button */}
        {path.length > 0 && (
          <button onClick={navigateBack} className="slideshow-back-button">Back</button>
        )}

        {/* Display folder navigation buttons */}
        {currentFolder && currentFolder.subFolders && currentFolder.subFolders.length > 0 && (
          <div className="slideshow-folder-nav">
            {currentFolder.subFolders.map((subFolder, index) => (
              <button key={index} onClick={() => navigateToFolder(subFolder)} className="slideshow-folder-button">
                {subFolder.folderName}
              </button>
            ))}
          </div>
        )}

        {/* Image slideshow */}
        <div className="slideshow">
          {images.length > 0 ? (
            <>
              <div className="slideshow-slide" onClick={openModal}>
                <img src={images[currentSlide]} alt={`Slide ${currentSlide + 1}`} className="slideshow-image" />
              </div>
              <button className="slideshow-nav-button prev" onClick={prevSlide}>‹</button>
              <button className="slideshow-nav-button next" onClick={nextSlide}>›</button>
            </>
          ) : (
            <div className="slideshow-placeholder">
              <p>No images available in this folder.</p>
            </div>
          )}
        </div>
      </div>

      {/* Modal for full-width image */}
      {isModalOpen && (
        <div className="slideshow-modal">
          <span className="slideshow-close-button" onClick={closeModal}>&times;</span>
          <div className="slideshow-caption">{caption}</div>
          <img className="slideshow-modal-content" src={images[currentSlide]} alt={`Full view slide ${currentSlide + 1}`} />
        </div>
      )}
    </div>
  );
};

export default Slideshow;
