import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: "<h1>Date Sheet - I-Unit Exam 2023</h1><br /><iframe src=\"images/mrcitisrsecschool/Misc/I-Unit Exam Date Sheet.pdf\" =\"\"=\"\"></iframe><br />" },];;

const services = [{icon:"fi fi-br-s",title:"Syllabus",content:"<h1>Class : NURSERY</h1><br /><embed src=\"images/mrcitisrsecschool/Misc/Nursery Syllabus.pdf#toolbar=1&navpanes=1&scrollbar=1\"  type=\"application/pdf\"     frameBorder=\"0\"     scrolling=\"auto\"     height=\"100%\"     width=\"100%\" ></embed> <center> <a target=\'_blank\' href=\"images/mrcitisrsecschool/Misc/Nursery Syllabus.pdf\">Download Above Pdf </a> </center> <br/> <br /><h1>CLASS : LKG</h1><br /><embed src=\"images/mrcitisrsecschool/Misc/Syllabus LKG.pdf#toolbar=1&navpanes=1&scrollbar=1\"  type=\"application/pdf\"     frameBorder=\"0\" ",link:"../Syllabus",},{icon:"fi fi-br-t",title:"Time-Table",content:"",link:"../TimeTable",},{icon:"fi fi-br-c",title:"Calendar",content:"<iframe src=\"images/mrcitisrsecschool/Misc/SchoolCalendarcompressed.pdf\" =\"\"=\"\"></iframe>",link:"../Calendar",},{icon:"fi fi-br-h",title:"Holidays’ Home Work",content:"<h1>Class : NURSERY</h1><br /><embed src=\"images/mrcitisrsecschool/Misc/GRADE-NURSERY.pdf#toolbar=1&navpanes=1&scrollbar=1\"  type=\"application/pdf\"     frameBorder=\"0\"     scrolling=\"auto\"     height=\"100%\"     width=\"100%\" ></embed> <center> <a target=\'_blank\' href=\"images/mrcitisrsecschool/Misc/GRADE-NURSERY.pdf\">Download Above Home Work</a> </center><br /><h1>CLASS : LKG</h1><br /><embed src=\"images/mrcitisrsecschool/Misc/GRADE-LKG.pdf#toolbar=1&navpanes=1&scrollbar=1\"  type=\"application/pdf\"     frameBorder=\"0\"     scrolling=\"au",link:"../HolidaysHomeWork",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Date Sheet'}
]

const DateSheet = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Date Sheet"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="DateSheet"
                subTitle="Date Sheet"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default DateSheet
