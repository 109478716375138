import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: "<br /><img src=\"images/mrcitisrsecschool/Misc/Sel2.jpg\" /><br /><br /><img src=\"images/mrcitisrsecschool/Misc/39.jpg\" />" },];;

const services = [{icon:"fi fi-br-c",title:"Cbse Result",content:"<br /><center><h1>2014-15(Class-X)</h1></center><img id=\"clip\" src=\"images/mrcitisrsecschool/Misc/2014-15(1).jpg\" /><br /><center><h1><br />2014-15(Class-XII)</h1></center><img src=\"images/mrcitisrsecschool/Misc/2014--15(1).jpg\" /><br /><center><h1><br />2015-16(Class-X &amp; XII)</h1></center><img src=\"images/mrcitisrsecschool/Misc/2015-16.jpg\" /><br /><center><h1><br />2016-17(Class-XII)</h1></center><img src=\"images",link:"../CBSEResult",},{icon:"fi fi-br-s",title:"Sports",content:"<div><span><br />The school has a spacious playground and has facilities for athletics, basketball, volley ball, handball, fencing etc. A separate play area for the primary section with safe and attractive playground equipment allows free play to the young ones. Besides the regular physical education classes, coaching is also imparted in games. Each child opts for a game of his/her interest. The school seeks co-operation from various sports agencies to avail the services of highly qualified coaches and instructors for the benefits of our budding sport developmen",link:"../Sports",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Exemplary Performances'}
]

const ExemplaryPerformances = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Exemplary Performances"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="ExemplaryPerformances"
                subTitle="Exemplary Performances"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default ExemplaryPerformances
