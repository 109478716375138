import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText = [{ text: "&nbsp;<br /><span>“Leader in Me is the ideal Blueprint for every school to change the world through education”<br /><br />   <div>      <img src=\"images/mrcitisrsecschool/Misc/Jaspreet (Nur to UKG).jpg\" />  <img src=\"images/mrcitisrsecschool/Misc/Poonamthakur.jpg\" />      <img src=\"images/mrcitisrsecschool/Misc/NeeruSharma.jpg\" />      <img src=\"images/mrcitisrsecschool/Misc/KiranDatta.jpg\" />      <img src=\"images/mrcitisrsecschool/Misc/Gurpreetkaursekhon.jpg\" /> </div>   <br />  <br /> <br /><br /><br /><br /><br /><br /><br /><br />&nbsp;<br /><br />Mrs. Poonam Thakur&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Mrs. Neeru Sharma&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Mrs. Kiran Datta&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Mrs. Gurpreet Kaur Sekhon<br /><br /><div><span>Mrs. Jaspreet, Head Pre-primary. <br />She is a voracious reader and an enthusiastic leader with a potential to do more.&nbsp;<br /><br />Mrs. Poonam, Head of Primary School is an enthusiastic leader with creativity in her skill and acts as a great booster for her team.&nbsp;<br /></span></div><div><span><br /></span></div><div><span>Mrs. Neeru Sharma and Mrs. Kiran Datta, Head of Middle School possess leadership skills with team spirit, hard work in her job profile. She believes in Academic excellence and fraises an achieving goal.&nbsp;</span></div><div><span><br /></span></div><div><span>Mrs. Gupreet Kaur Sekhon, Head of Senior School, she has vast experience and has made innumerable contribution in the field of education. She believes in sincerity, dedication and loyalty towards her commitments.&nbsp;</span></div><div><br /></div></span>" },];;

const services = [{icon:"fi fi-br-a",title:"About The Principal",content:"<br /><span>Bright and child friendly classroom provide the right ambience for learning. The classrooms are spacious, well ventilated and appropriately equipped. The furniture has been designed keeping in view the physical comfort and safety of the children. Every child enjoys the privilege of having a separate seating arrangement with a cubby hole to keep their belongings. The windows are covered with blinds cater for shade as well as sunlight according to the requirement. The beautiful display Boards inside and outside the classrooms make them appear extremely",link:"../AboutthePrincipal",},{icon:"fi fi-br-t",title:"Teachers",content:"<br /><img src=\"images/mrcitisrsecschool/Misc/Staffpic.jpg\" /><img src=\"images/mrcitisrsecschool/Misc/AdminStaff.jpg\" /><br /><br /><div>The main strength of an educational institute is its teachers. The teachers are backbone of an educational system. The faculty of M.R. Citi Public School consist of highly qualified teachers. Who have extensive teaching experience in their respective fields.&nbsp;<div><span>For the teachers of M.R. Citi Public School ‘",link:"../Teachers",},{icon:"fi fi-br-t",title:"Teachers Enrichment Programme",content:"<br /><div><span>Both staff and administrations participate in training workshops for personal improvement and professional learning opportunities.&nbsp;</span></div><div><span>Administration, teachers and their supporting staff members need opportunities for meaningful professional development as they strive to impact students.&nbsp;</span></div><div><span>These enrichment programs conducted in MR Citi Public School focuses on helping staff build character, explore new paradigms and develop highly ",link:"../TeachersEnrichmentProgramme",},]
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Heads'}
]

const Heads = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Heads"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="Heads"
                subTitle="Heads"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle=""
                services={services}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default Heads
