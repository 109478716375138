import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from "../Title";
import "./style.scss";

// images
 
import teamMember2 from "../../images/expert/2.jpg";
import teamMember3 from "../../images/expert/3.jpg";
import teamMember4 from "../../images/expert/4.jpg";
import teamMember5 from "../../images/expert/5.jpg";
import teamMember6 from "../../images/expert/6.jpg";

const teams = [
  {
    name: "Ramji Dass",
    level: "Chairman",
    image: "/images/mrcitisrsecschool/chairman/chairman.jpg",
    content:"In this fast-changing world, we are experiencing a rapid growth in the field of education. We at M.R. Citi Sr. Sec. Public School wish to develop the personality of the children. They are like a tiny seed and sign of life for them is struggle for growth. Education, especially in the modern world, has become so expensive that its tentacles have reached the space, the moon and much beyond as well.",
    socialMedia: ["facebook", "twitter", "linkedin"],
    link:"/#/Chairmandesk",
  },
  {
    name: "Sumit Choudhary",
    level: "Director",
    content:"Our school represents a cross section of the society and is a rich blanket of multi cultural diversity. Children come to us from varied cultural, social, racial and religious background. Within school they learn to live together as one big family, regardless of their roots and create, newer & stronger bonds with each other that will last them a life time. The minds of children, contrary to our beliefs, are open. ",
    image: "/images/mrcitisrsecschool/director/director.jpg",
    socialMedia: ["facebook", "twitter", "linkedin"],
    link:"/#/DirectorDesk",
  },
  {
    name: "Ritu Batra",
    level: "Principal",
    image: "/images/mrcitisrsecschool/Principal/PRINCIPAL.jpg",
    content:"In this age of digital supremacy education systems being the basic challenges of equipped students with new abilities and skills required to be competitive in global world wide at the same time producing individuals who are responsible adults with a sound value system, good citizens both of the country and of the world. Education is not just school curriculum but 'Real Life Curriculum' ",
    socialMedia: ["facebook", "twitter", "linkedin"],
    link:"/#/PrincipalDesk",
  },

 
];

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const MessageBoard = ({ className, title, subTitle, slider, noGutters }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className={!noGutters ? "row" : "row no-gutters"}>
          <div className="col-12">
            <SectionTitle title={title} subTitle={subTitle} />
          </div>
          {slider ? (
            <div className="col-12">
              <Slider className="teamSlideArea" {...settings}>
                {teams.map((team, i) => (
                  <div key={i} className="teamWrapper">
                    <a href={team.link}>
                    <div className="teamImage">
                      <img src={team.image} alt="" />
                      <p style={{margin:"10px"}}>
                      {team.content}
                      </p>
                    </div>
                    </a>
                    <div className="teamContent">
                      <h3>{team.name}</h3>
                      <span>{team.level}</span>
                  
                      <ul>
                        {team.socialMedia.map((social) => (
                          <li key={social}>
                         
                            <a href="#">
                              <i
                                className={`fa fa-${social}`}
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        ))}
                      </ul>
             
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <Fragment>
              {teams.map((team, i) => (
                <div key={i} className="col-lg-4 col-md-6 col-12">
                  <div className="teamWrapper">
                    <div className="teamImage">
                      <img src={team.image} alt="" />
                    </div>
                    <div className="teamContent">
                      <h3>{team.name}</h3>
                      <span>{team.level}</span>
                      <ul>
                        {team.socialMedia.map((social) => (
                          <li key={social}>
                            <a href="#">
                              <i
                                className={`fa fa-${social}`}
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        ))}
                      </ul>
         
                    </div>
                  </div>
                </div>
              ))}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
export default MessageBoard;
